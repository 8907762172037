import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './pages';
import ReduxProvider from './contextProviders/ReduxProvider'
import "react-multi-carousel/lib/styles.css";

const App = ({ history }) => (
  <ReduxProvider>
    <Router history = {history}>
      <Routes />
    </Router>
  </ReduxProvider>
);

export default App;