import * as PropTypes from 'prop-types';
import loader from '../../../assets/loading.svg';
import './Loading.scss';

const Loading = (props) => {
	const { isLoading, isLoadingMessage } = props;

	if(isLoading){
		return (
			<div>
				<img className="loader_" src={loader} alt='hold on... while we try to get what you are looking for'/>
				{isLoadingMessage && <div className="loading-message">{isLoadingMessage}</div>}
			</div>
		);
	} else {
		return (
			<div></div>
		)
	}
};

Loading.propTypes = {
	isLoading: PropTypes.bool.isRequired,
}


export default Loading;