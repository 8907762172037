import React, { useEffect, useState } from 'react';
import { Simple } from '../../components/layouts';
import { Row, Col, Container, Spinner, Modal, Card } from 'react-bootstrap';
import { Property, Slider, Loading } from '../../components/common';
import { useSelector } from 'react-redux';
import { propertyDataSelectors } from '../../store/slices/propertyData';
import { SearchFilter } from '../../components/common';
import logo from '../../assets/1503.png';
import { Link } from 'react-router-dom';
import { getCurrencyRate } from '../../store/slices/propertyData/selectors';
import './Home.scss';
import CouplesImage from "../../assets/rentals-for-couples.jpeg";
import GroupImage from "../../assets/rentals-for-larger groups.jpeg"


  const Home = (props) => {
      const manager = useSelector(propertyDataSelectors.getPropertyManager);
      const allProperties = useSelector(propertyDataSelectors.getAllProperties);
      const [featuredProperties, setFeaturedProperties] = useState([]);
      const isLoading = useSelector(propertyDataSelectors.isLoading);
      const currencyRate = useSelector(getCurrencyRate);
      const [unitsForCouples, setUnitsForCouples] = useState([]);
      const [unitsForGroups, setUnitsForGroups] = useState([]);
      const [showModal, setShowModal] = useState(false);
      const [selectedProperty, setSelectedProperty] = useState(null);
      const excludedManagerId = 2435;

    
      const handleOpenModal = (propertyType) => {
        setSelectedProperty(propertyType);
        setShowModal(true);
      }
    
      const handleCloseModal = () => {
        setSelectedProperty(null);
        setShowModal(false);
      }

      const renderPropertyHighlights = () => {
        if (manager.id !== excludedManagerId) {
            return (
                <Container className="pt-5 pb-4">
                    <div className="title-bar py-2 mb-4 border-bottom">
                        <h2> Property Highlights </h2>
                    </div>
                    <Row>
                        <Col sm={6}>
                            <Card onClick={() => handleOpenModal('couples')} className="card-style">
                                <Card.Img variant="top" src={CouplesImage} className="card-img-style" />
                                <Card.Title className="card-title-style">
                                    Units for Couples
                                </Card.Title>
                            </Card>
                        </Col>
                        <Col sm={6}>
                            <Card onClick={() => handleOpenModal('groups')} className="card-style">
                                <Card.Img variant="top" src={GroupImage} className="card-img-style" />
                                <Card.Title className="card-title-style">
                                    Units for Larger Groups
                                </Card.Title>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            );
        }
        return null;
    };

    
      const renderProperties = (properties) => {
        return properties.map(property => (
          <Col lg={4} md={6} sm={12}>
          <Property
              property={property}
              weekdayRate={(property.weekdayRate * currencyRate).toFixed(2)}
              bedrooms={property.bedrooms}
              beds={property.beds}
              sleeps={property.sleeps}
              bathrooms={property.bathrooms}
              img={property.image.mediumUrl}
              title={property.property_name}
              desc={property.city}
              property_id={property.id}
            />
          </Col>
        ));
      }

      const filterPropertiesForGroups = (properties, unitsForCouples) => {
        const couplesIds = unitsForCouples.map(property => property.id);
        return properties.filter(property => property.sleeps >= 8 && property.beds >= 2 && !couplesIds.includes(property.id));
    };
      
      const filterPropertiesForCouples = (properties) => {
          return properties.filter(property => property.beds === 1);
      };
    
      useEffect(() => {
          if (allProperties && allProperties.results) {
              const couples = filterPropertiesForCouples(allProperties.results).slice(0, 4);
              const couplesIds = couples.map(property => property.id);
              const groups = filterPropertiesForGroups(allProperties.results, couplesIds).slice(0, 4);
              setUnitsForCouples(couples);
              setUnitsForGroups(groups);
          }
      }, [allProperties]);

    
      useEffect(() => {
          if (
            !manager.customSitesDetails ||
            !manager.customSitesDetails.featuredProperties ||
            manager.customSitesDetails.featuredProperties.length === 0
          ) {
            if (allProperties && allProperties.results) {
              const resultsCopy = [...allProperties.results];
              const randomProperties = resultsCopy
                .sort(() => 0.5 - Math.random())
                .slice(0, 2);

                setFeaturedProperties(formatProperties(randomProperties, 'random'));
              }
          } else {

              setFeaturedProperties(formatProperties(manager.customSitesDetails.featuredProperties, 'manager'));
          }
        }, [manager, allProperties]);

        const formatProperties = (properties, source) => {
          return properties.map((property) => {
            let formattedProperty;
            if (source === 'random') {
              const bathrooms = typeof property.bathrooms === 'object' 
                ? (property.bathrooms.full || 0) + (property.bathrooms.half || 0)
                : property.bathrooms || 0;
              formattedProperty = {
                ...property,
                title: property.property_name,
                weekdayRate: property.weekdayRate,
                bedrooms: property.bedrooms,
                bathrooms,
                img: property.image.mediumUrl,
              };
            } else {
              formattedProperty = {
                ...property,
                title: property.name,
                weekdayRate: property.startingRate,
                bedrooms: property.rooms,
                bathrooms: property.baths,
                img: property.imageMedium,
              };
            }      
            return formattedProperty;
          });
        };
        
        

      const search = () => {
          props.history.push('/our-properties');
      }

      return (
          <Simple src={logo} alt="logo">
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "100vh" }}
            >
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <div 
                className="slider_section" 
                style={{ 
                  minHeight: manager.sliderImages && Object.keys(manager.sliderImages).length > 0 ? '200px' : '400px' 
                }}>
                  <Slider message={manager.customSitesDetails ? manager.customSitesDetails.welcomeMessage : null} customClass={'slider_options slider_options_new'} src={manager.sliderImages ? Object.keys(manager.sliderImages).map(el => manager.sliderImages[el]['fullImage']) : []} alt='slider image' />
                  <SearchFilter search={search} variant="white"/>
              </div>


              {featuredProperties && featuredProperties.length > 0 ? <Container className="f-property pt-4 pb-2">
                  <div className="title-bar py-2 border-bottom">
                      <h2> Featured Properties </h2>
                      <Link to="/our-properties" className="allprop">View All Properties</Link>
                  </div>
                  <Row>
                      <Loading isLoading={isLoading} />
                      {featuredProperties && featuredProperties.map((property) => (
                          <Col lg={3} md={4} sm={6} xs={12}><Property property={property} weekdayRate={(property.weekdayRate * currencyRate).toFixed(2)} bedrooms={property.bedrooms} beds={property.beds} sleeps={property.sleeps} bathrooms={property.bathrooms} img={property.img} title={property.title} desc={property.city} property_id={property.id} /></Col>
                      ))}
                  </Row>
              </Container>:null}
           
              {renderPropertyHighlights()}

          <Modal 
          show={showModal} 
          onHide={handleCloseModal}
          dialogClassName="custom-modal-style"
          >
            <Modal.Header closeButton>
              <Modal.Title>{selectedProperty === 'couples' ? 'Units for Couples' : 'Units for Larger Groups'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {selectedProperty === 'couples'
                  ? renderProperties(unitsForCouples)
                  : renderProperties(unitsForGroups)
                }
              </Row>
            </Modal.Body>
          </Modal>
       


            </>
        )}
      </Simple>
      )
  };

  export default Home;
