import React from 'react';
import './CustomDropdown.css';

const CustomDropdown = ({ className, title, options, selectedValues, onChange, variant, countries }) => {
  const handleClick = (optionCity) => {
    const option = options.find((o) => o.city === optionCity);
    const newValues = selectedValues.includes(option)
      ? selectedValues.filter((value) => value !== option)
      : [...selectedValues, option];

    onChange(newValues, option);
  };

  const groupedOptions = options.reduce((acc, option) => {
    if (!acc[option.country]) {
      acc[option.country] = [];
    }
    acc[option.country].push(option);
    return acc;
  }, {});

  return (
    <div className={`custom-dropdown ${className} ${variant}`}>
      <div className="dropdown-title">{title}</div>
      <div className="dropdown-options">
        {Object.entries(groupedOptions).map(([country, cities]) => (
          <div key={country} className="dropdown-country-group">
            <div className="dropdown-country">{country}</div>
            {cities.map((option) => (
              <div
                key={option.city}
                className={`dropdown-option ${selectedValues.includes(option) ? 'selected' : ''}`}
                onClick={() => handleClick(option.city)}
              >
                <input
                  type="checkbox"
                  checked={selectedValues.includes(option)}
                  onChange={() => {}}
                />
                {option.city}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomDropdown;
