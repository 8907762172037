export const SLICE_NAME = 'propertyData';
export const PROPERTY_DATA_SLICE = 'propertySlice';

export const PROPERTIES = 'properties';
export const ALLPROPERTIES = 'allProperties';
export const PROPERTY = 'property';
export const LOADING = 'loading';
export const ERROR = 'error';
export const PROPERTY_MANAGER = 'propertyManager';
export const FILTERS = 'filters';
export const CURRENCY_RATE = 'currencyRate';
export const SELECTED_CURRENCY = 'selectedCurrency';
export const SELECTED_CURRENCY_SYMBOL = 'selectedCurrencySymbol';
export const PROPERTY_MANAGER_INFO = 'propertyManagerInfo';