import { Simple } from '../../components/layouts';
import { Row, Col, Container } from 'react-bootstrap';
import image from '../../assets/1503.png';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlinePhone } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { SendInquiryForm } from '../../components/common';
import { propertyDataSelectors } from '../../store/slices/propertyData';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Home = () => {
	const manager = useSelector(propertyDataSelectors.getPropertyManager);

	return (
		<Simple src={image} alt='logo'>
			<Container className="mt-5 mb-4">
				<Row>
				<Col sm={12}>
							<Breadcrumb>
								<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
								<Breadcrumb.Item active>Contact</Breadcrumb.Item>
							</Breadcrumb>
							 
						</Col>
				</Row>
				<Row className="mt-4 ">
					<Col sm={8}>
						<h4 className="subheading pb-3 mb-4">Send Message</h4>
						<SendInquiryForm />
					</Col>
					<Col sm={4}>
						<h4 className="subheading pb-3 mb-4">Let's Get In Touch</h4>
						<p>Please contact us with any questions you have and we will do our best to get back to you as soon as possible.</p>
						<div className="d-flex pt-3 mb-3">
							<ul>
								<li className="d-flex mb-4">
									<AiOutlineHome size={30} className="text-dark mr-2 " />
									<div class="contact-address">

										<span>{manager.companyName} {manager.address}, {manager.city}<br /> {manager.country}</span> </div>
								</li>
								<li className="d-flex mb-4">

									<AiOutlinePhone size={27} className="text-dark mr-2 " />
									<div class="contact-address">

										

										<span><a href="tel:+19094575457">{manager.customerServicePhone}</a></span> </div>


								</li>
								<li className="d-flex mb-4">


									<AiOutlineMail size={30} className="text-dark mr-2 " />
									<div class="contact-address">

										<span>{manager.customerServiceEmail}</span> </div>


								</li>


							</ul>
						</div>
					</Col>

				</Row>
			</Container>
		</Simple>
	)
};

export default Home;