import React, { useEffect, useState } from "react";
import { Simple } from '../../components/layouts';
import { Row, Col, Container } from 'react-bootstrap';
import image from '../../assets/1503.png';
// import recent_img from '../../assets/about-gallery.jpg';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './about.scss';
import { useSelector } from 'react-redux';
import { propertyDataSelectors } from '../../store/slices/propertyData';
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
// import { RiReplyAllLine } from "react-icons/ri";
// import { BiHeart } from "react-icons/bi";
import config from '../../config';
import axios from 'axios';
import * as moment from 'moment';


const Home = () => {
	const [reviews, setReviews] = useState([]);
	const manager = useSelector(propertyDataSelectors.getPropertyManager);

	const getCompanyReviews = async (id) => {
		const { endPoint, baseURL, token } = config.companyReviews;
		const response = await axios.get(`${baseURL}?endpoint=${endPoint}${id}`, { headers: { "Authorization": `Bearer ${token}` } });
		setReviews(response.data);
	}

	useEffect(() => {
		if(manager.id){
			getCompanyReviews(manager.id);
		}
	}, [manager]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Simple src={image} alt='logo'>
			<Container className="mt-5 mb-4">
				<Row>
					<Col sm={12}>
						<Breadcrumb>
							<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
							<Breadcrumb.Item active>About</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
				</Row>
				<Row className="mt-4 justify-content-center text-center">
					<Col sm={10}>
						<h2 className="pb-3 mb-4">About Us</h2>
						<p>{manager.companyDescription}</p>
					</Col>
				</Row>
				{reviews.map(el => <Row className="mt-4 justify-content-center">
					<Col sm={10}>
						<Row className="mb-2">
							<Card className="w-100">
								<Card.Body>
									<Row className="align-items-center">
										<Col sm={12}>
											<h4 className=""><span className="mb-2 float-left text-dark">{el.Review.title}</span></h4>
											<p className="text-right">
												{Array(5).fill(null).map((item, index) => {
													if(index + 1 <= parseInt(el.Review.ratings)){
														return (
															<span className="text-warning"><AiFillStar /></span>
														)
													}
													return (
														<span className="text-warning"><AiOutlineStar /></span>
													)
												})}
											</p>
											<div class="clearfix"></div>
											<p>{el.Review.description}</p>
											<p>
												<p className="text-secondary text-right">{moment(el.Review.created).format('MMM DD, YYYY')}</p>
												{/* <a href="/" className="float-right btn btn-outline-primary ml-2"> <RiReplyAllLine /> Reply</a>
												<a href="/" className="float-right btn text-white btn-danger"> <BiHeart /> Like</a> */}
											</p>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Row>
					</Col>
				</Row>)}
			</Container>
		</Simple>
	)
};

export default Home;