import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import propertyService from '../../../services/propertyService';
import { SLICE_NAME, PROPERTIES, PROPERTY, LOADING, ERROR, PROPERTY_MANAGER, FILTERS, ALLPROPERTIES, CURRENCY_RATE, SELECTED_CURRENCY, SELECTED_CURRENCY_SYMBOL, PROPERTY_MANAGER_INFO } from './constants';
import *  as selectors from './selectors';

export const propertiesData = createAsyncThunk(`${SLICE_NAME}/Properties`, async (shouldBeSuccessful) =>
	propertyService.getAllProperties(shouldBeSuccessful)
);

export const allPropertiesData = createAsyncThunk(`${SLICE_NAME}/AllProperties`, async (shouldBeSuccessful) =>
	propertyService.getAllProperties(shouldBeSuccessful)
);

export const propertyDetails = createAsyncThunk(`${SLICE_NAME}/Property`, async (shouldBeSuccessful) =>
	propertyService.getPropertyDetails(shouldBeSuccessful)
);

export const propertyManager = createAsyncThunk(`${SLICE_NAME}/PropertyManager`, async (shouldBeSuccessful) =>
	propertyService.getPropertyManager(shouldBeSuccessful)
);

export const filters = createAsyncThunk(`${SLICE_NAME}/filters`, async (shouldBeSuccessful) => 
propertyService.getFilters(shouldBeSuccessful)
);

export const propertyManagerInfo = createAsyncThunk(`${SLICE_NAME}/PropertyManagerInfo`, async (shouldBeSuccessful) =>
	propertyService.getPropertyManagerInfo(shouldBeSuccessful)
);

export const initialState = {
	[PROPERTIES]: {},
	[ALLPROPERTIES]: {},
	[PROPERTY]: {},
	[LOADING]: false,
	[ERROR]: '',
	[PROPERTY_MANAGER]: {},
	[FILTERS]: {},
	[CURRENCY_RATE]: 1,
	[SELECTED_CURRENCY]: '',
	[SELECTED_CURRENCY_SYMBOL]: '',
	[PROPERTY_MANAGER_INFO]: {},
};

export const slice = createSlice({
	name: SLICE_NAME,
	initialState,
	reducers: {
		setProperties(state, action) {
			state[PROPERTIES] = action.payload;
		},
		setAllProperties(state, action) {
			state[ALLPROPERTIES] = action.payload;
		},
		setPropertyDetail(state, action) {
			state[PROPERTY] = action.payload;
		},
		setPropertyManager(state, action) {
			state[PROPERTY_MANAGER] = action.payload;
		},
		setFilters(state, action) {
			state[FILTERS] = action.payload;
		},
		setCurrencyRate(state, action) {
			state[CURRENCY_RATE] = action.payload;
		},
		setSelectedCurrency(state, action) {
			state[SELECTED_CURRENCY] = action.payload;
		  },
		  setSelectedCurrencySymbol: (state, action) => { 
			state[SELECTED_CURRENCY_SYMBOL] = action.payload;
		  },
		setAvailableProperties(state, action) {
			state[PROPERTIES] = action.payload;
		},
	},
	extraReducers: {
		[propertiesData.pending]: (state) => {
			state[LOADING] = true;
		},
		[propertiesData.fulfilled]: (state, action) => {
			state[LOADING] = false;
			state[PROPERTIES] = action.payload;
			state[ERROR] = '';
		},
		[propertiesData.rejected]: (state, action) => {
			state[LOADING] = false;
			state[PROPERTIES] = initialState[PROPERTIES];
			state[ERROR] = action.error?.message;
		},
		[allPropertiesData.pending]: (state) => {
			// state[LOADING] = true;
		},
		[allPropertiesData.fulfilled]: (state, action) => {
			state[ALLPROPERTIES] = action.payload;
			state[ERROR] = '';
		},
		[allPropertiesData.rejected]: (state, action) => {
			state[ALLPROPERTIES] = initialState[ALLPROPERTIES];
			state[ERROR] = action.error?.message;
		},
		[propertyDetails.pending]: (state) => {
			state[LOADING] = true;
		},
		[propertyDetails.fulfilled]: (state, action) => {
			state[LOADING] = false;
			state[PROPERTY] = action.payload;
			state[ERROR] = '';
		},
		[propertyDetails.rejected]: (state, action) => {
			state[LOADING] = false;
			state[PROPERTY]
			= initialState[PROPERTY];
			state[ERROR] = action.error?.message;
			},
			[propertyManager.pending]: (state) => {
			state[LOADING] = true;
			},
			[propertyManager.fulfilled]: (state, action) => {
			state[LOADING] = false;
			state[PROPERTY_MANAGER] = action.payload;
			state[ERROR] = '';
			},
			[propertyManager.rejected]: (state, action) => {
			state[LOADING] = false;
			state[PROPERTY_MANAGER] = initialState[PROPERTIES];
			state[ERROR] = action.error?.message;
			},
			[filters.pending]: (state) => {
			// state[LOADING] = true;
			},
			[filters.fulfilled]: (state, action) => {
			state[LOADING] = false;
			state[FILTERS] = action.payload;
			state[ERROR] = '';
			},
			[filters.rejected]: (state, action) => {
			state[LOADING] = false;
			state[FILTERS] = initialState[FILTERS];
			state[ERROR] = action.error?.message;
			},
			[propertyManagerInfo.pending]: (state) => {
				state[LOADING] = true;
			},
			[propertyManagerInfo.fulfilled]: (state, action) => {
				state[LOADING] = false;
				state[PROPERTY_MANAGER_INFO] = action.payload;
				state[ERROR] = '';
			},
			[propertyManagerInfo.rejected]: (state, action) => {
				state[LOADING] = false;
				state[PROPERTY_MANAGER_INFO] = initialState[PROPERTY_MANAGER_INFO];
				state[ERROR] = action.error?.message;
			},
			},
			});
			
			export const propertyDataActions = {
			propertiesData,
			propertyDetails,
			propertyManager,
			filters,
			allPropertiesData,
			propertyManagerInfo,
			...slice.actions,
			};
			
			export const propertyDataSelectors = {
			...selectors,
				getCurrencyRate: (state) => state[SLICE_NAME][CURRENCY_RATE],

  };
			
			export default slice.reducer;