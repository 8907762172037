import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { propertyDataActions, propertyDataSelectors } from '../../../store/slices/propertyData';
import convertCurrency from '../../../services/currency';
import './selector.scss'

const CurrencySelector = ({ baseCurrency, onRateChange }) => {
  const currencies = {
    USD: { symbol: '$' },
    EUR: { symbol: '€' },
    BRL: { symbol: 'R$' },
    MXN: { symbol: 'MX$' },
    GBP: { symbol: '£' },
  };

  const selectedCurrency = useSelector(propertyDataSelectors.getSelectedCurrency);
  const dispatch = useDispatch();

  const handleCurrencyChange = async (e) => {
    const targetCurrency = e.target.value;
    const targetSymbol = currencies[targetCurrency].symbol;
    dispatch(propertyDataActions.setSelectedCurrency(targetCurrency));
    dispatch(propertyDataActions.setSelectedCurrencySymbol(targetSymbol));

    if (targetCurrency !== baseCurrency) {
      const conversionRate = await convertCurrency(baseCurrency, targetCurrency, 1);
      onRateChange(conversionRate);
    } else {
      onRateChange(1);
    }
  };

  return (
    <select
      value={selectedCurrency}
      onChange={handleCurrencyChange}
      className="currency-selector-dropdown"
    >
      {Object.entries(currencies).map(([currency, details]) => (
        <option key={currency} value={currency}>
          {details.symbol} {currency}
        </option>
      ))}
    </select>
  );
};

export default CurrencySelector;
