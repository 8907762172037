import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import Slider from '../Slider';
import './imagebanner.css'

const ImageBanner = ({ primarySrc, secondarySrcs }) => {
    const [showSlider, setShowSlider] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (src) => {
        setSelectedImage(src);
        setShowSlider(true);
    };

    return (
        <div className="image-banner">
            {/* Primary image */}
            <div className="primary-image-container">
                <img src={primarySrc} alt='primary' onClick={() => handleImageClick(primarySrc)}/>
            </div>
            {/* Secondary images */}
            <div className="secondary-image-container">
                {secondarySrcs.map((el, index) => (
                    <img key={index} src={el} alt={'secondary ' + index} onClick={() => handleImageClick(el)}/>
                ))}
            </div>
            
            <Modal show={showSlider} onHide={() => setShowSlider(false)} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Image Slider</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Slider 
                        customClass={'slider_options slider_options_new'} 
                        src={[primarySrc, ...secondarySrcs]} 
                        selectedImage={selectedImage}
                        preserveAspectRatio={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSlider(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

ImageBanner.propTypes = {
    primarySrc: PropTypes.string.isRequired,
    secondarySrcs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ImageBanner;