import React from 'react';
import { Simple } from '../../components/layouts';
import image from '../../assets/1503.png';
import notFound from '../../assets/404.png';

function NotFound() {
    return (
        <Simple src={image} alt='logo'>
            <div className="d-flex justify-content-center align-items-center w-100">
                <img style={{ width: '100%' }} src={notFound} alt="404 not found" />
            </div>
        </Simple>
    );
}

export default NotFound