import React, { useState, useEffect } from 'react';
import { Simple } from '../../components/layouts';
import { Row, Col, Container, Modal } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import image1 from '../../assets/shutterstock_425004079.jpg';
import image2 from '../../assets/Telluride-Jazz-123.jpg';
import image3 from '../../assets/18update-coaster1-superJumbo.jpg';
import image4 from '../../assets/best-unknown-national-parks-734x490.jpg';
import image5 from '../../assets/Hadestown_action2-960.jpg';
import image6 from '../../assets/Horseback-Ride-e1577992287766.jpeg';
import image7 from '../../assets/MexicoMuseums.webp';
import image8 from '../../assets/EventsandFestivals.jpeg';
import image9 from '../../assets/EventsandFestivals.webp';
import image10 from '../../assets/ExcursionsandTours.jpeg';
import image11 from '../../assets/cenote-in-mexico-3.jpeg'
import { Category } from '../../components/common';
import config from '../../config';
import axios from 'axios';
import './style.scss';
import { useSelector } from 'react-redux';
import { propertyDataSelectors } from '../../store/slices/propertyData';
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { Loading } from '../../components/common';


const ThingsToDo = () => {
	const [smShow, setSmShow] = useState(null);
	const [things, setThings] = useState([]);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const manager = useSelector(propertyDataSelectors.getPropertyManager);

	const defaultData = [
		{ name: 'Restaurants and Pubs', image: image1, selector: 'restaurants_and_pubs' },
		{ name: 'Events and Festivals', image: image2, selector: 'events_and_festivals' },
		{ name: 'Excursions and Rentals', image: image6, selector: 'excursions_and_rentals' },
		{ name: 'Theme Parks and Attractions', image: image3, selector: 'theme_parks_and_attractions' },
		{ name: 'Entertainment and Cinema', image: image5, selector: 'entertainment_and_cinema' },
		{ name: 'Other important things', image: image4, selector: 'other_important_things' },
	];

	const mexicoData = [
		{ name: 'Restaurants and Pubs', image: image1, selector: 'restaurants_and_pubs' },
		{ name: 'Events and Festivals', image: image8, selector: 'events_and_festivals' },
		{ name: 'Excursions and Rentals', image: image10, selector: 'excursions_and_rentals' },
		{ name: 'Theme Parks and Attractions', image: image11, selector: 'theme_parks_and_attractions' },
		{ name: 'Entertainment and Cinema', image: image9, selector: 'entertainment_and_cinema' },
		{ name: 'Other important things', image: image7, selector: 'other_important_things' },
	];


	const setModalData = (obj) => {
		let thingArray = things.filter(el => el.Thing.category === obj.selector);
		setSmShow({ name: obj.name, array: thingArray });
	}


	const getThingsToDo = async (id) => {
		try {
			const { endPoint, baseURL, token } = config.thingsToDo;
			const response = await axios.get(`${baseURL}?endpoint=${endPoint}${id}`, { headers: { "Authorization": `Bearer ${token}` } });
			setThings(response.data)
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	}

	useEffect(() => {
		if (manager.id) {
			getThingsToDo(manager.id);
			if (manager.country === 'Mexico') {
				setData(mexicoData);
			} else {
				setData(defaultData);
			}
		}
	}, [manager]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Simple src={'image'} alt='logo'>
			<Container className="mt-5 mb-4">
				<Row>
					<Col sm={12}>
						<Breadcrumb>
							<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
							<Breadcrumb.Item active>Things To Do</Breadcrumb.Item>
						</Breadcrumb>

					</Col>
				</Row>
				<Row className="mt-4 ">
					<Col sm={12}>
						<h4 className="subheading pb-3 mb-4">Categories</h4>
					</Col>
				</Row>
				<Row className="justify-content-center text-center">
					<Col sm={12}>
						<Loading isLoading={loading} />
					</Col>
				</Row>
				<Row>
					<Col>
						<Row>
							{!loading && data.map(el => {
								return (
									<Col onClick={() => setModalData(el)} className="cursor-pointer" lg={4} md={4} sm={6} xs={12}>
										<Category item={el} />
									</Col>
								)
							})}
						</Row>
					</Col>
				</Row>
			</Container>
			<Modal size="lg" show={smShow !== null} onHide={() => setSmShow(null)} aria-labelledby="Send-Inquiry">
				<Modal.Header closeButton>
					<Modal.Title id="Send-Inquiry" className="text-center d-block w-100">
						<div className="justify-content-center align-items-center d-flex">
							{smShow ? smShow.name : null}
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{smShow && smShow.array && smShow.array.length === 0 ? <div className="w-100 text-center">
							No records found...
					</div> : null}
					{smShow && smShow.array && smShow.array.map(el => {
						el = el.Thing;
						return (
							<div className="row pt-2 pb-2 align-items-center"><div className="col-sm-4"><div className="title"><h6><strong>{el.title} </strong></h6></div>
								<p className="mb-0 small"> <svg stroke="currentColor" fill="currentColor" stroke-width="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M10.368 19.102c.349 1.049 1.011 1.086 1.478.086l5.309-11.375c.467-1.002.034-1.434-.967-.967l-11.376 5.308c-1.001.467-.963 1.129.085 1.479l4.103 1.367 1.368 4.102z"></path></svg> {el.city}, {el.state}, {el.country} </p><p className="small"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M17.707,12.293c-0.391-0.391-1.023-0.391-1.414,0l-1.594,1.594c-0.739-0.22-2.118-0.72-2.992-1.594 s-1.374-2.253-1.594-2.992l1.594-1.594c0.391-0.391,0.391-1.023,0-1.414l-4-4c-0.391-0.391-1.023-0.391-1.414,0L3.581,5.005 c-0.38,0.38-0.594,0.902-0.586,1.435c0.023,1.424,0.4,6.37,4.298,10.268s8.844,4.274,10.269,4.298c0.005,0,0.023,0,0.028,0 c0.528,0,1.027-0.208,1.405-0.586l2.712-2.712c0.391-0.391,0.391-1.023,0-1.414L17.707,12.293z M17.58,19.005 c-1.248-0.021-5.518-0.356-8.873-3.712c-3.366-3.366-3.692-7.651-3.712-8.874L7,4.414L9.586,7L8.293,8.293 C8.054,8.531,7.952,8.875,8.021,9.205c0.024,0.115,0.611,2.842,2.271,4.502s4.387,2.247,4.502,2.271 c0.333,0.071,0.674-0.032,0.912-0.271L17,14.414L19.586,17L17.58,19.005z"></path></svg> {el.phone}</p></div><div className="col-sm-6"><div className="list-details"><p>{el.description}</p></div></div><div className="col-sm-2"><div className="rating text-warning mb-1">
									{Array(5).fill(null).map((item, index) => {
										if (index + 1 <= parseInt(el.ratings)) {
											return (
												<span className="text-warning"><AiFillStar /></span>
											)
										}
										return (
											<span className="text-warning"><AiOutlineStar /></span>
										)
									})}
								</div><br /><a href={'tel:' + el.phone} className="mt-1 btn btn-primary btn-sm" >Call Now</a></div></div>
						)
					})}
				</Modal.Body>
			</Modal>
		</Simple>
	);
}

export default ThingsToDo;