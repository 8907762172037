import ReactDOM from 'react-dom';
import { createMemoryHistory, createBrowserHistory } from 'history';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './index.scss';

const entryPoint = (rootElement, { browserHistory, path, navigationListener}) =>{
	const history = browserHistory || createMemoryHistory({initialEntries: [path]});

	if(navigationListener) {
		history.listen(navigationListener);
	}
	
	ReactDOM.render(<App history={history}/>, rootElement);

	return {
		onNavigate: ({ pathname: nextPathname }) => {
			const { pathname } = history.location;
			if(pathname !== nextPathname) {
				history.push(nextPathname);
			}
		},
	};
};

if(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'production'){
	const rootElement = document.querySelector('#_homeRootElement');
	if(rootElement) {
		entryPoint(rootElement, { browserHistory: createBrowserHistory() });
	}
}

export default entryPoint;