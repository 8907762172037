import React from 'react';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import { propertyDataSelectors } from '../../../store/slices/propertyData';
import Favicon from 'react-favicon';

function MetaData() {
    const manager = useSelector(propertyDataSelectors.getPropertyManager);


    if (manager.customSitesDetails && manager.customSitesDetails.bannerTitle) {
        return (
            <>
                <Favicon url={manager.logo && manager.logo['Digital Logo'] ? manager.logo['Digital Logo']['url'] : null} />
                <MetaTags>
                    <title>{manager.customSitesDetails.bannerTitle}</title>
                    <meta name="description" content={manager.companyDescription} />
                    <meta property="og:title" content={manager.customSitesDetails.bannerTitle} />
                    <meta property="og:image" content={manager.logo && manager.logo['Digital Logo'] ? manager.logo['Digital Logo']['url'] : null} />
                </MetaTags>
            </>
        )
    } else if (manager.customSitesDetails && manager.customSitesDetails.customLogo) {
        return (
            <>
                <Favicon url={manager.customSitesDetails.customLogo['fullImage']} />
                <MetaTags>
                    <title>{manager.companyName}</title>
                    <meta name="description" content={manager.companyDescription} />
                    <meta property="og:title" content={manager.companyName} />
                    <meta property="og:image" content={manager.customSitesDetails.customLogo['fullImage']} />
                </MetaTags>
            </>
        );
    } else if (manager.logo) {
        return (
            <>
                <Favicon url={manager.logo['Digital Logo'] ? manager.logo['Digital Logo']['url'] : null} />
                <MetaTags>
                    <title>{manager.companyName}</title>
                    <meta name="description" content={manager.companyDescription} />
                    <meta property="og:title" content={manager.companyName} />
                    <meta property="og:image" content={manager.logo['Digital Logo'] ? manager.logo['Digital Logo']['url'] : null} />
                </MetaTags>
            </>
        );
    }
    return null
}

export default MetaData;