import React, { useEffect, useLayoutEffect } from "react";
import { Route, Switch, useLocation } from 'react-router-dom';
import config from './route-config';
import { useDispatch, useSelector } from 'react-redux';
import { propertyDataActions, propertyDataSelectors } from '../store/slices/propertyData';


const Routes = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const manager = useSelector(propertyDataSelectors.getPropertyManager);
	const elements = config.map((route) => <Route {...route} />);

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	useEffect(() => {
		dispatch(propertyDataActions.propertyManager());
		dispatch(propertyDataActions.allPropertiesData({}));
	}, [dispatch]);

	useEffect(() => {
		const root = document.documentElement;
		if (manager.customSitesDetails) {
			if (manager.customSitesDetails['trim-color']) {
				root?.style.setProperty("--primary", "#" + manager.customSitesDetails['trim-color']);
			}
			const headColor = manager.customSitesDetails.headColor;
            if (headColor === "") {
				root?.style.setProperty("--dark-gray", "transparent");
			} else if (headColor && headColor.length === 6) {
				root?.style.setProperty("--dark-gray", `#${headColor}FF`);
			} else if (headColor && headColor.length === 8) {
				root?.style.setProperty("--dark-gray", `#${headColor}`);
			}
			if (manager.customSitesDetails['trim-color-secondary']) {
				root?.style.setProperty("--secondry", "#" + manager.customSitesDetails['trim-color-secondary']);
			}
			if (manager.customSitesDetails['header-menu-color']) {
				root?.style.setProperty("--menu", "#" + manager.customSitesDetails['header-menu-color']);
			}
			if (manager.customSitesDetails['header-menu-hover-color']) {
				root?.style.setProperty("--menu-hover", "#" + manager.customSitesDetails['header-menu-hover-color']);
			}
			if (manager.customSitesDetails.footerColor) {
				root?.style.setProperty("--footer-color", "#" + manager.customSitesDetails.footerColor);
			}
			if (manager.customSitesDetails.footerColor && (manager.customSitesDetails.footerColor === "000000" || manager.customSitesDetails.footerColor === "000")){
				root?.style.setProperty("--footer-heading-color", "#ffffff");
			}
			if (manager.customSitesDetails['footer-text-color']) {
				root?.style.setProperty("--footer-text", "#" + manager.customSitesDetails['footer-text-color']);
			}
			if (manager.customSitesDetails['footer-text-hover-color']) {
				root?.style.setProperty("--footer-text-hover", "#" + manager.customSitesDetails['footer-text-hover-color']);
			}
		}
	}, [manager]);

	return (
		<Switch>
			{elements}
		</Switch>
	);
};

export default Routes;