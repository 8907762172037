import React from 'react';
import { useSelector } from 'react-redux';
import { propertyDataSelectors } from '../../../store/slices/propertyData';

const AddressBlock = (props) => {

    const manager = useSelector(propertyDataSelectors.getPropertyManager);

    return (
        <div className="address">
            <p><strong className="d-block">{manager.companyName}</strong>
                <span className="d-block">{manager.address}, {manager.state}</span>
                <span className="d-block">{manager.country}</span>
            </p>
            <p className="mb-0"><a href={'tel:' + manager.customerServicePhone}>{manager.customerServicePhone}</a></p>
            {/* <p><a href="/">Contact Us</a></p> */}
        </div>
    );
}

export default AddressBlock;