import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Slider from '../Slider';

const ImageGallery = ({ property }) => {
    const [showSlider, setShowSlider] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    return (
        <>
            <Modal.Body className="image-gallery">
                {property.property_images ? Object.keys(property.property_images).map((key, index) => (
                    <div 
                        key={index} 
                        onClick={() => {setSelectedImage(property.property_images[key]['url']); setShowSlider(true);}}
                    >
                        <img src={property.property_images[key]['url']} alt={`property-${index}`} />
                    </div>
                )) : "No images available"}
            </Modal.Body>

            <Modal show={showSlider} onHide={() => setShowSlider(false)} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Image Slider</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Slider 
                        customClass={'slider_options slider_options_new'} 
                        src={property.property_images ? Object.keys(property.property_images).map(el => property.property_images[el]['url']) : []} 
                        selectedImage={selectedImage}
                        preserveAspectRatio={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSlider(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ImageGallery;
