import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { propertyDataSelectors, propertyDataActions } from '../../../store/slices/propertyData';
import { useSelector, useDispatch } from 'react-redux';
import Slider from '@material-ui/core/Slider';

const PriceRange = ({ currencyRate }) => {
    const dispatch = useDispatch();
    const filters = useSelector(propertyDataSelectors.getFilters);
    const maxPrice = Math.round(1000 * currencyRate);
    const selectedCurrencySymbol = useSelector(propertyDataSelectors.getSelectedCurrencySymbol);

    const getAdjustedMinMax = () => {
  const minPrice = 0;
  const maxPrice = 1000;

  return {
    min: Math.round(minPrice * currencyRate),
    max: Math.round(maxPrice * currencyRate),
  };
};


    const handleChange = (event, newValue) => {
        let obj = JSON.parse(JSON.stringify(filters));
        obj['minPrice'] = Math.round(newValue[0] / currencyRate);
        obj['maxPrice'] = newValue[1] === maxPrice ? `${maxPrice}+` : Math.round(newValue[1] / currencyRate);
        dispatch(propertyDataActions.filters(obj));
    }

    return (
        <aside className="aside_widget mb-4">
            <h5 className="heading pb-3 mb-4">Price Range</h5>
            <Slider
  value={[
    filters.minPrice ? Math.round(filters.minPrice * currencyRate) : getAdjustedMinMax().min,
    filters.maxPrice && filters.maxPrice !== `${getAdjustedMinMax().max}+`
      ? Math.round(filters.maxPrice * currencyRate)
      : getAdjustedMinMax().max,
  ]}
  onChange={handleChange}
  min={getAdjustedMinMax().min}
  max={getAdjustedMinMax().max}
  valueLabelDisplay="auto"
  aria-labelledby="range-slider"
/>

<Row>
  <Col>{selectedCurrencySymbol}{filters.minPrice ? Math.round(filters.minPrice * currencyRate) : getAdjustedMinMax().min}</Col>
  <Col className="text-right">{selectedCurrencySymbol}{filters.maxPrice ? (filters.maxPrice === `${getAdjustedMinMax().max}+` ? filters.maxPrice : Math.round(filters.maxPrice * currencyRate)) : `${getAdjustedMinMax().max}+`}</Col>
</Row>


        </aside>
    );
}

export default PriceRange;