import React, { useEffect, useState } from "react";
import { Simple } from '../../components/layouts';
import { Row, Col, Container } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import image from '../../assets/1503.png';
import { propertyDataSelectors } from '../../store/slices/propertyData';
import { useSelector } from 'react-redux';
import config from '../../config';
import axios from 'axios';
import { Loading } from '../../components/common';

const CustomPage = (props) => {
	const manager = useSelector(propertyDataSelectors.getPropertyManager);
	const activeId = props.match.params.id;
	const [page, setPage] = useState([]);
	const [loading, setLoading] = useState(true);

	const getCompanyPages = async (id) => {
		try {
			const { endPoint, baseURL, token } = config.pages;
			const response = await axios.get(`${baseURL}?endpoint=${endPoint}${id}`, { headers: { "Authorization": `Bearer ${token}` } });
			let allPages = response.data.filter(el => el['Page'].slug === activeId);
			setLoading(false)
			if (allPages.length > 0) {
				setPage(allPages[0]['Page']);
			}
		} catch (err) {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (manager.id) {
			getCompanyPages(manager.id);
		}
	}, [manager]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Simple src={image} alt='logo'>
			<Container className="mt-5 mb-4">
				<Row>
					<Col sm={12}>
						<Breadcrumb>
							<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
							<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} active>{page.title}</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
				</Row>
				<Row className="justify-content-center text-center">
					<Col sm={12}>
						<Loading isLoading={loading} />
					</Col>
				</Row>
				{!loading ? <Row className="mt-4 justify-content-center">
					<Col sm={10}>
						<Row>
							<Col>
								<div className="w-100 blog_container" dangerouslySetInnerHTML={{ __html: page.description }}>
								</div>
							</Col>
						</Row>
					</Col>
				</Row> : null}
			</Container>
		</Simple>
	)
};

export default CustomPage;