import { PropertyCard } from './Styled';
import './index.scss';

const Category = (props) => {
	return (
		<div className="category-card mt-4">
			<PropertyCard>
				<PropertyCard.Img variant="top" src={props.item.image} alt='card-image' />
				<PropertyCard.Body>
					<PropertyCard.Title>{props.item.name}</PropertyCard.Title>
				</PropertyCard.Body>
			</PropertyCard>
		</div>

	);
};

export default Category;