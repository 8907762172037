import * as PropTypes from 'prop-types';
import { PropertyCard } from './Styled';
import { Link } from 'react-router-dom';
import './index.scss';
import { useSelector } from 'react-redux';
import { propertyDataSelectors } from '../../../store/slices/propertyData';

const Property = (props) => {
	const { img, title, desc, property_id, bedrooms, beds, sleeps, bathrooms, weekdayRate, property } = props;
	const selectedCurrencySymbol = useSelector(propertyDataSelectors.getSelectedCurrencySymbol);
	const fullBathrooms = typeof bathrooms === 'object'
		? (bathrooms.full || 0) + (bathrooms.half || 0)  // If object, sum up full and half bathrooms
		: bathrooms || 0;

	const handleRecentlyViewed = (data) => {
		let array = localStorage.getItem('recents') ? JSON.parse(localStorage.getItem('recents')) : [];
		let already = array.filter(el => el.id === data.id);
		if (already.length === 0) {
			array.unshift(data);
			if (array.length > 2) {
				array.splice(array.length - 1, 1);
			}
			localStorage.setItem('recents', JSON.stringify(array));
		}
	}

	// Function to process the image URL
    const processImgUrl = (url) => {
		// Check if url is defined and includes 'aki_policy=small'
		if (url && url.includes('aki_policy=small')) {
			// Replace 'aki_policy=small' with '' (i.e., remove it)
			return url.replace('aki_policy=small', '');
		}
		// If url is undefined or doesn't include 'aki_policy=small', just return it as is
		return url;
	}
	

    // Process the img URL before using it
    const processedImg = processImgUrl(img);

	return (

		<div onClick={() => { handleRecentlyViewed(property) }} className="property-card mt-4">
			<Link to={`/property/details/${property_id}`}>
				<PropertyCard>
				<PropertyCard.Img variant="top" src={processedImg} alt='card-image' />
					<div className="overlay"></div>
					<PropertyCard.Body>
						<div className="price bg-primary text-white d-inline-block py-2 px-2 ml-3 h6">From <span className="d-block">{selectedCurrencySymbol} {weekdayRate}</span></div>
						<PropertyCard.Title>{title}</PropertyCard.Title>
						<PropertyCard.Text>
							{desc}
						</PropertyCard.Text>
						<div className="property-hover">
							<div className="property-details">
								<span>Room:</span>
								<span>{bedrooms}</span>
							</div>
							<div className="property-details">
								<span>Beds:</span>
								<span>{beds}</span>
							</div>
							<div className="property-details">
								<span>Sleeps:</span>
								<span>{sleeps}</span>
							</div>
							<div className="property-details">
								<span>Bathrooms:</span>
								<span>{fullBathrooms}</span>
								</div>
						</div>
					</PropertyCard.Body>
				</PropertyCard>
			</Link>
		</div>

	);
};

Property.propTypes = {
	img: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	desc: PropTypes.string.isRequired,
	property_id: PropTypes.number.isRequired,
}


export default Property;