import * as PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";
import { useEffect } from 'react';
import './slider.scss';
const Slider = (props) => {
    const { src, preserveAspectRatio = false, selectedImage } = props;
    const arrowColor = preserveAspectRatio ? 'black' : 'white';

     // Preload images
  useEffect(() => {
    src.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
    });
  }, [src]);

    // Move selectedImage to the front of the array
    const orderedSrc = selectedImage
        ? [selectedImage, ...src.filter(image => image !== selectedImage)]
        : src;

    return (
        <Carousel 
        nextIcon={<RiArrowRightSLine color={arrowColor}/>} 
        prevIcon={<RiArrowLeftSLine color={arrowColor}/>}
    >
        {orderedSrc.map((el, index) => (
            <Carousel.Item key={index}>
                {preserveAspectRatio ?
                    <img className={props.customClass} src={el} alt={'image' + el} style={{ objectFit: 'contain', width: '100%', height: '100%'}} />
                    :
                    <div className={props.customClass} style={{backgroundImage:`url(${el})`}}></div>
                }
                <Carousel.Caption className="d-sm-flex h-120 align-items-sm-center justify-content-sm-center">
                    {props.message ? <h1 className="text-white">{props.message}</h1> : null}
                </Carousel.Caption>
            </Carousel.Item>
        ))}
    </Carousel>
    );
};
Slider.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired
}

export default Slider;