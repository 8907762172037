import axios from 'axios';
import config from '../config';


const getAllProperties = async (request) => {
	let query = '';
	Object.keys(request).forEach(key => {
		if (key === 'cities') {
			request[key] = request[key].map(el => el.city);
		}
		query += `&${key}=${request[key]}`
	});
	const { endPoint, baseURL, token } = config.propertiesSearchApi;
	const response = await axios.get(`${baseURL}?endpoint=${endPoint}${query}`, { headers: { "Authorization": `Bearer ${token}` } });
	return response.data;
}

const getPropertyDetails = async (request) => {
	if (request.id) {
		const { endPoint, baseURL, token } = config.propertyDetailsApi;
		const response = await axios.get(`${baseURL}?endpoint=${endPoint}/${request.id}`, { headers: { "Authorization": `Bearer ${token}` } });
		if (response.data[0]) {
			return response.data[0];
		}
		return { error: true }
	}
	return {};
}

const getPropertyManager = async (request) => {
	const { endPoint, baseURL, token } = config.propertyManagerApi;
	const response = await axios.get(`${baseURL}?endpoint=${endPoint}`, { headers: { "Authorization": `Bearer ${token}` } });
	return response.data;
}

const getPropertyAvailability = async (request) => {
	const { endPoint, baseURL, token } = config.propertyAvailabilityApi;
	const response = await axios.post(`${baseURL}?endpoint=${endPoint}`, request, { headers: { "Authorization": `Bearer ${token}` } });
	return response.data;
}

const getCountries = async (request) => {
	const { endPoint, baseURL, token } = config.countries;
	const response = await axios.get(`${baseURL}?endpoint=${endPoint}`, { headers: { "Authorization": `Bearer ${token}` } });
	return response.data['_embedded']['country'];
}

const getStates = async (request) => {
	const { endPoint, baseURL, token } = config.states;
	const response = await axios.get(`${baseURL}?endpoint=${endPoint}/${request.state}`, { headers: { "Authorization": `Bearer ${token}` } });
	return response.data;
}

const makeReservation = async (request) => {
	const { endPoint, baseURL, token } = config.reservation;
	const response = await axios.post(`${baseURL}?endpoint=${endPoint}`, request, { headers: { "Authorization": `Bearer ${token}` } });
	return response.data;
}

const sendInquiry = async (request) => {
	const { endPoint, baseURL, token } = config.inquiry;
	const response = await axios.post(`${baseURL}?endpoint=${endPoint}`, request, { headers: { "Authorization": `Bearer ${token}` } });
	return response.data;
}

const getFilters = async (request) => {
	return request;
}

const getPropertyCalendar = async (request, endAt) => {
	const { endPoint, baseURL, token } = config.propertyCalendarApi;
	const endDate = endAt ? `&endAt=${endAt}` : '';
	const response = await axios.get(`${baseURL}?endpoint=${endPoint}/${request.propertyId}/calendar${endDate}`, { headers: { "Authorization": `Bearer ${token}` } });
	const calendarData = response.data.map(data => ({ ...data, leadTime: parseInt(data.leadTime) }));
	return calendarData;
  };

  const getPropertyChangeover = async ({ propertyId }) => {
    const { endPoint, baseURL, token } = config.propertyChangeoverApi;
    const response = await axios.get(`${baseURL}?endpoint=${endPoint}&property=${propertyId}`, { headers: { "Authorization": `Bearer ${token}` } });
    return response.data;
};

const getPropertyManagerInfo = async (request) => {
	const { endPoint, baseURL, token } = config.propertyManagerInfo;
	const response = await axios.get(`${baseURL}?endpoint=${endPoint}`, { headers: { "Authorization": `Bearer ${token}` } });
	return response.data;
}

const propertyService = {
	getAllProperties,
	getPropertyDetails,
	getPropertyManager,
	getFilters,
	getPropertyAvailability,
	getCountries,
	getStates,
	makeReservation,
	sendInquiry,
	getPropertyCalendar,
	getPropertyChangeover,
	getPropertyManagerInfo
}

export default propertyService;