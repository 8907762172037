import axios from 'axios';

const API_KEY = "ZyEyw03nlWbPc3r01klUh0HlAGTmEqVW";

function convertCurrency(from, to, amount) {
  const url = `https://api.apilayer.com/exchangerates_data/convert?from=${from}&to=${to}&amount=${amount}`;

  const headers = { apikey: API_KEY };

  const config = {
    method: 'get',
    url: url,
    headers: headers
  };

  return axios(config)
    .then(response => response.data.result)
    .catch(error => console.log('Error', error));
}

export default convertCurrency;
