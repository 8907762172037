import React, { useEffect } from "react";
import { Simple } from '../../components/layouts';
import { Row, Col, Container, Table } from 'react-bootstrap';
import image from '../../assets/1503.png';
import Card from 'react-bootstrap/Card';
import { BiPrinter } from "react-icons/bi";
import './confirmation.scss';
import { Property } from '../../components/common';
import { useSelector, useDispatch } from 'react-redux';
import { propertyDataSelectors, propertyDataActions } from '../../store/slices/propertyData';
import * as moment from 'moment';
import { getCurrencyRate } from '../../store/slices/propertyData/selectors'

const Confirmation = (props) => {
	if (!props.location.state || !props.location.state.propertyData) {
		props.history.push('/');
	}

	const property = props.location.state ? props.location.state.propertyData : {};
	const quote = props.location.state ? props.location.state.quote : {};
	const booking = props.location.state ? props.location.state.bookingData : {};
	const guestCount = props.location.state ? props.location.state.guestCount : 0;
	const startAt = props.location.state ? props.location.state.startAt : 0;
	const endAt = props.location.state ? props.location.state.endAt : 0;
	const dispatch = useDispatch();
	const properties = useSelector(propertyDataSelectors.getProperties);
	const manager = useSelector(propertyDataSelectors.getPropertyManager);
	const currencyRate = useSelector(getCurrencyRate);
	const selectedCurrencySymbol = useSelector(propertyDataSelectors.getSelectedCurrencySymbol);
	  

	const printData = () => {
		var prtContent = document.getElementById("reciept");
		var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
		WinPrint.document.write(prtContent.innerHTML);
		WinPrint.document.close();
		WinPrint.focus();
		WinPrint.print();
	}


	useEffect(() => {
		dispatch(propertyDataActions.propertyDetails());
		dispatch(propertyDataActions.propertiesData({}));
	}, [dispatch]);

	return (
		<Simple src={image} alt='logo' currencyRate={currencyRate}>
			<Container className="mt-5 mb-4">

				<Row className="justify-content-md-center">
					<Col sm={10}>
						<Row>
							<Col sm={12}>
								<div className="px-4 py-4">
									<h2>Great news!</h2>
									<p>Your reservation for <strong className="text-primary"> {property.property_name}</strong>  has been confirmed and you are ready to go! We just sent you a welcome email with more details about your stay. Feel free to contact us if you have any questions.</p>
									<button className="btn btn-primary" type="button" onClick={() => printData()}><BiPrinter /> Print my receipt</button>
								</div>
							</Col>
						</Row>

						<Row>
							<Col sm={12} id="reciept">
								<Card className="Confirmation">
									<Card.Body>
										<div className="Confirmation-heading mb-4 pt-4 d-flex justify-content-between align-items-end">
											<h1 className="mb-0">Confirmation</h1>

											<p className="mb-0">
												{/* <span>Booking #</span>
												<strong className="d-block">A58E34</strong> */}
											</p>
											<p className="mb-0">
												<span>Today's Date</span>
												<strong className="d-block">{moment().format('MMM DD, YYYY')}</strong>
											</p>
										</div>
										
										<hr />
										<Row >
											<Col sm={6}>x
												<address>
													<h4 className="font-weight-bold">Company</h4>
													<strong>{manager.companyName}</strong>
													<br />
													{manager.address}, {manager.city}
													<br />
													{manager.state}, {manager.country}
													<div className="adr_info mt-2">
														<p>
															<span><strong className="col-4 pl-0">Phone:</strong> <a href={'tel:' + manager.customerServicePhone}>{manager.customerServicePhone}</a></span>
														</p>
														<p>
															<span><strong className="col-4 pl-0">Email:</strong> <a href={`mailto:${manager.customerServiceEmail}`}>{manager.customerServiceEmail}</a></span>
														</p>
														{/* <p>
															<span><strong className="col-4 pl-0">Website:</strong> <a href="https://www.modernplaces.com/">{booking.guest && booking.guest.propertyManager && booking.guest.propertyManager.email}</a></span>
														</p> */}
													</div>
												</address>
											</Col>
											<Col sm={6}>
												<address>
													<h4 className="font-weight-bold">Guest</h4>
													<strong>{booking.guest && booking.guest.firstName}</strong>
													<br />
													{booking.guest && booking.guest.address}
													<br />
													{booking.guest && booking.guest.city}, {booking.guest && booking.guest.state && booking.guest.state.name}, {booking.guest && booking.guest.country && booking.guest.country.name}, {booking.guest && booking.guest.postalCode}
													<div className="adr_info mt-2">
														<p>
															<span><strong className="col-4 pl-0">Phone:</strong> <a className="text-muted disabled" href="tel:1231231234">{booking.guest && booking.guest.phone}</a></span>
														</p>
														<p>
															<span><strong className="col-4 pl-0">Email:</strong> <a className="text-muted disabled" href={`mailto:${booking.guest && booking.guest.email}`}>{booking.guest && booking.guest.email}</a></span>
														</p>
														{/* <p>
															<span><strong className="col-4 pl-0">Payment:</strong> <span className="text-muted disabled" href="#">Visa XXXX-XXXX-XXXX-1234</span></span>
														</p> */}
													</div>
												</address>
											</Col>
										</Row>
										<hr />
										{/* <Row>
											<Col>
												<h6 className="font-weight-bold">Suitability</h6>
												<p className="text-muted mb-0">Pets allowed, Children/Family Friendly and Handicap Accessible</p>
											</Col>
										</Row>
										<hr /> */}
										<Row >
											<Col sm={6}>
												<address>
													<h4 className="font-weight-bold">Trip Details</h4>


													<div className="adr_info mt-2">
														<p>
															<span><strong className="col-4 pl-0">Duration:</strong> <span>{quote.rates && Object.keys(quote.rates).length} Nights</span></span>
														</p>
														<p>
															<span><strong className="col-4 pl-0">Guests:</strong> <span>{guestCount} Guests</span></span>
														</p>
														<p>
															<span><strong className="col-4 pl-0">Check In:</strong> <span>{startAt}</span></span>
														</p>
														<p>
															<span><strong className="col-4 pl-0">Check Out:</strong> <span>{endAt}</span></span>
														</p>
													</div>
												</address>
											</Col>
											<Col sm={6}>
												<address>
													<h4 className="font-weight-bold">Property Address</h4>
													<strong>{property.property_name}</strong>
													<div>

														{property.property_address}
														<br />
														{property.property_city}, {property.property_state}
														<br />
														{property.property_country}
													</div>

												</address>
											</Col>
										</Row>
										<Row>
											<Col>
												<Table className="border-0 description">
													<thead className="bg-light">
														<tr>
															<th>Description</th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>{quote.rates && Object.keys(quote.rates).length} Nights:</td>
															<td>
															{selectedCurrencySymbol}{
																quote.subTotal &&
																(quote.subTotal * currencyRate).toFixed(2).replace(/\.00$/, '')
															}
															</td>
														</tr>
														<tr>
															<td>Taxes/Fees:</td>
															<td>
															{selectedCurrencySymbol}{
																quote.taxAmount &&
																(quote.taxAmount * currencyRate).toFixed(2).replace(/\.00$/, '')
															}
															</td>
														</tr>
														</tbody>

												</Table>
												<hr />
												<Table className="border-0 total-price">
													<tbody>
														<tr>
															<td className="text-right display-5"><strong>Trip Total:</strong> <span>
                          {selectedCurrencySymbol}{(
                            quote.total &&
                            (quote.total * currencyRate).toFixed(2).replace(/\.00$/, '')
                          )}
                        </span></td>
														</tr>
													</tbody>
												</Table>
												<hr />
												<p> <strong>It was popularised in the 1960s</strong> with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
						</Row>

						<Row>
							<Col>

								<Row className="mb-4 mt-5 pt-4">
									<Col sm={12} className="mt-3">
										<h4><strong>Want to book another rental?</strong></h4>
										<p><strong><a href="/">Click here</a></strong> to find an additional rental for your specified dates or select an option below:</p>
									</Col>

									{properties.results && properties.results.map((property, index) => {
										if (index <= 2) {
											return (<Col sm={4}><Property property={property} weekdayRate={(property.weekdayRate * currencyRate).toFixed(2)} bedrooms={property.bedrooms} beds={property.beds} sleeps={property.sleeps} bathrooms={property.bathrooms} img={property.image.mediumUrl} title={property.property_name} desc={property.city} property_id={property.id} /></Col>)
										}
										return null
									})}

								</Row>
							</Col>
						</Row>
					</Col>
				</Row>






			</Container>
		</Simple>
	)
};

export default Confirmation;