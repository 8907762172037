import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import propertyService from '../../../services/propertyService';
import loader from '../../../assets/loading.svg';
import * as moment from 'moment';
import { propertyDataSelectors } from '../../../store/slices/propertyData';
import { useSelector } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './booknow.scss';


const BookNow = (props) => {

    const [isAvailable, setIsAvailable] = React.useState(false);
    const [guests, setGuests] = React.useState(1);
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [quote, setQuote] = React.useState([]);
    const [nightlyRates, setNightlyRates] = React.useState([]);
    const [nightlyFees, setNightlyFees] = React.useState([]);
    const [isFeeDisplay, setFeeDisplay] = React.useState(false);
    const [isRateDisplay, setRateDisplay] = React.useState(false);
    const [calendarData, setCalendarData] = React.useState(null);
    const selectedCurrencySymbol = useSelector(propertyDataSelectors.getSelectedCurrencySymbol);
    const [focusedInput, setFocusedInput] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [availabilityError, setAvailabilityError] = React.useState('');
    const [blockedDays, setBlockedDays] = React.useState(new Set());
    const [changeoverData, setChangeoverData] = React.useState(null);


    
    


    const convertCurrency = (amount) => {
        return amount * props.conversionRate;
      };

      const checkAvailability = async (startdate, enddate) => {
        setLoading(true);
        let data = await propertyService.getPropertyAvailability({ propertyId: props.activeId, startAt: startdate, endAt: enddate, guestCount: guests, customSite: 1 });


        setIsAvailable(data.isAvailable);
        setError(data.isAvailable ? '' : 'The property is not available on the selected dates.');
        setQuote(data.quote);
        setNightlyRates(data.quote.rates ? data.quote.rates :'bub');
        setNightlyFees(data.quote.fees);
        setLoading(false);
    }
    
    const handleDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
    
        if (startDate && endDate) {
            const start = moment(startDate);
            const end = moment(endDate);
            const days = end.diff(start, 'days');
    
            const isUnavailable = calendarData.some(data => {
                const rangeStart = moment(data.startAt.date);
                const rangeEnd = moment(data.endAt.date);
                return !data.available && (start.isBetween(rangeStart, rangeEnd, null, '[]') || end.isBetween(rangeStart, rangeEnd, null, '[]'));
            });
    
            if (isUnavailable) {
                setIsAvailable(false);
                setAvailabilityError('The selected dates are unavailable.');
                return;  // Return after setting the availability error
            }
    
            // Get the minimumStay for the selected date range
            const minStay = getMinimumStayForRange(startDate, endDate);
            const maxStay = getMaximumStayForRange(startDate, endDate);
    
            if (minStay && days < minStay) {
                setIsAvailable(false);
                setAvailabilityError(`This property is not available for less than ${minStay} days.`);
                return;
            } else if (maxStay && days > maxStay) {
                setIsAvailable(false);
                setAvailabilityError(`This property is not available for more than ${maxStay} days.`);
                return;
            }
            
            setIsAvailable(true);
            setAvailabilityError('');
            setLoading(true); 
            checkAvailability(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"));
        }
    };
    
    const getMinimumStayForRange = (start, end) => {
        let minStay = Infinity;
    
        calendarData.forEach(data => {
            const rangeStart = moment(data.startAt.date);
            const rangeEnd = moment(data.endAt.date);
    
            if (start.isBetween(rangeStart, rangeEnd, null, '[]') || end.isBetween(rangeStart, rangeEnd, null, '[]') || rangeStart.isBetween(start, end, null, '[]') || rangeEnd.isBetween(start, end, null, '[]')) {
                if (data.minimumStay < minStay) {
                    minStay = data.minimumStay;
                }
            }
        });
    
        return minStay === Infinity ? null : minStay;
    }

    const getMaximumStayForRange = (start, end) => {
        let maxStay = -Infinity;
    
        calendarData.forEach(data => {
            const rangeStart = moment(data.startAt.date);
            const rangeEnd = moment(data.endAt.date);
    
            if (start.isBetween(rangeStart, rangeEnd, null, '[]') || end.isBetween(rangeStart, rangeEnd, null, '[]') || rangeStart.isBetween(start, end, null, '[]') || rangeEnd.isBetween(start, end, null, '[]')) {
                if (data.maximumStay > maxStay) {
                    maxStay = data.maximumStay;
                }
            }
        });
    
        return maxStay === -Infinity ? null : maxStay;
    }
    
    
      
    const handleFocusChange = focusedInput => {
        setFocusedInput(focusedInput);
    };


    const isCheckinDayBlocked = (day) => {
        // Define the format used to compare dates
        const dateFormat = 'YYYY-MM-DD';
        
        // Format the day to the same format
        const currentDay = day.format(dateFormat);
        
        // Check if the day is a blocked day
        const isDayBlocked = blockedDays.has(currentDay);
        
        return isDayBlocked;
    };
    
    
    const isCheckoutDayBlocked = (day) => {
        // Define the format used to compare dates
        const dateFormat = 'YYYY-MM-DD';
        
        // Format the day to the same format
        const currentDay = day.format(dateFormat);
        
        // Check if the day is a blocked day
        const isDayBlocked = blockedDays.has(currentDay);
    
        if (isDayBlocked) {
            return true;
        }
    
        // If it's not a blocked day, then check if it's not a permissible checkout day
        if (!changeoverData) {
            return false;
        }
    
        const weekday = day.day();
        const checkOutAllowed = weekday === 0 ? changeoverData.checkOutSunday
                        : weekday === 1 ? changeoverData.checkOutMonday
                        : weekday === 2 ? changeoverData.checkOutTuesday
                        : weekday === 3 ? changeoverData.checkOutWednesday
                        : weekday === 4 ? changeoverData.checkOutThursday
                        : weekday === 5 ? changeoverData.checkOutFriday
                        : weekday === 6 ? changeoverData.checkOutSaturday
                        : null;
    
        return !checkOutAllowed;
    };
    


    
    

    useEffect(() => {
        async function fetchCalendarData() {
            const twoYearsFromNow = moment().add(2, 'years').format('YYYY-MM-DD');
            const response = await propertyService.getPropertyCalendar({ propertyId: props.activeId }, twoYearsFromNow);
            
            let newBlockedDays = new Set();
            response.forEach(data => {
                if (!data.available) {
                    const rangeStart = moment(data.startAt.date);
                    const rangeEnd = moment(data.endAt.date);
                    let day = moment(rangeStart);
                    while (day.isSameOrBefore(rangeEnd)) {
                        newBlockedDays.add(day.format('YYYY-MM-DD'));
                        day.add(1, 'day');
                    }
                }
            });
            setBlockedDays(newBlockedDays);
            setCalendarData(response);
        }
        const fetchChangeoverData = async () => {
            const response = await propertyService.getPropertyChangeover({ propertyId: props.activeId });
            if (response._embedded && response._embedded.change_over && response._embedded.change_over.length > 0) {
                setChangeoverData(response._embedded.change_over[0]);
            } else {
                console.error('Unexpected response structure from fetchChangeoverData.');
            }
        }
      
        fetchCalendarData();
        fetchChangeoverData();
      }, [props.activeId]);

    let leadTime;

        if (calendarData) {
        const dataWithLeadTime = calendarData.find(data => 'leadTime' in data);
        
        if (dataWithLeadTime) {
            leadTime = dataWithLeadTime.leadTime;
        }
        }

    let nightlyRatesTotal = 0;
    let nightlyRatesDisplay = [];
    Object.keys(nightlyRates).forEach(function(key) {
        nightlyRatesDisplay.push(<li>{selectedCurrencySymbol}{convertCurrency(nightlyRates[key]).toFixed(2)}</li>);
        nightlyRatesTotal = nightlyRatesTotal + nightlyRates[key];
    });
    let nightlyFeesTotal = 0;
    let nightlyFeesDisplay = [];
    Object.keys(nightlyFees).forEach(function(key) {
        nightlyFeesDisplay.push(<li>{key}:{selectedCurrencySymbol}{convertCurrency(nightlyFees[key]).toFixed(2)}</li>);
        nightlyFeesTotal = nightlyFeesTotal + nightlyFees[key];
    });
    const handleFeeToggle = () => {
        setFeeDisplay(!isFeeDisplay);
    };
    const handleRateToggle = () => {
        setRateDisplay(!isRateDisplay);
    };
    let totalExtraGuestFees = quote.extraGuestFees 
    ? Object.values(quote.extraGuestFees).reduce((a, b) => a + b, 0) 
    : 0;
      
    return (
        <>
            <Row className="booknow ">
            <Col className="booking-column">
        <label>Dates</label>
        <div className="datepicker-wrap">
        {loading ? (
            <div>Loading...</div>
        ) : calendarData ? (
            <DateRangePicker
    noBorder={true}
    small={false}
    startDate={startDate}
    startDateId="check_in_date"
    endDate={endDate}
    endDateId="check_out_date"
    onDatesChange={handleDatesChange}
    focusedInput={focusedInput}
    onFocusChange={handleFocusChange}
    numberOfMonths={2}
    showClearDates={true}
    startDatePlaceholderText="Check in"
    endDatePlaceholderText="Check out"
    isOutsideRange={day => {
        const today = moment().add(leadTime, 'days');
        const twoYearsFromNow = moment().add(2, 'years');
        return day.isBefore(today) || day.isAfter(twoYearsFromNow);
    }}
    minimumNights={focusedInput === 'endDate' && startDate ? getMinimumStayForRange(startDate, moment(startDate).add(1, 'days')) : 0}
    isDayBlocked={day => {
        if (focusedInput === "startDate") {
            return isCheckinDayBlocked(day);
        } else if (focusedInput === "endDate") {
            return isCheckoutDayBlocked(day);
        }
        return false; 
    }}
/>
            ) : (
              <div>Loading...</div>
            )}
            {availabilityError && <div className="text-danger">{availabilityError}</div>}
          </div>
        <label>Guests</label>
        <select value={guests} onChange={(e) => setGuests(e.target.value)} className="dropdown form-control">
          {props.property && props.property.property_max_guest_count ? Array(props.property.property_max_guest_count).fill().map((el, index) => {
            return (
              <option value={index + 1}>{index + 1} Guests</option>
            )
          }) : null}
        </select>
      </Col>
                <Col className="pt-3" sm={12}>
                    <span className='text-sm text-danger'>{error}</span>
                </Col>
                <Col sm={12}>
                        {isAvailable ?
                            <Link
                            to={`/payment?pid=${props.activeId}&startAt=${startDate ? startDate.format("MMM DD, YYYY") : ''}&endAt=${endDate ? endDate.format("MMM DD, YYYY") : ''}&guests=${guests}`}
                            className="w-100 mt-4 mb-2 btn btn-primary" variant="primary"
                            >
                            {loading ? <img style={{ width: '25px' }} src={loader} alt='loading' /> : 'Book It Now'}
                            </Link>
                        : <button
                            className="w-100 mt-4 mb-2 btn btn-primary"
                            variant="primary"
                            >
                            {loading ? <img style={{ width: '25px' }} src={loader} alt='loading' /> : 'Book It Now'}
                            </button>}
                        </Col>
                {(quote.total > 0 && !error) ?
                    <Col className="pt-3" sm={12}>
                    <div className='total-box'>
                        <div className='total-head'>Total Details</div>
                        <div className='text-sm'>
                            <span className={'bold-text'}>Cleaning Fees:</span> {selectedCurrencySymbol}{convertCurrency(quote.cleaningFee > 0 ? quote.cleaningFee : '').toFixed(2)}
                        </div>
                        <div className='text-sm'>
                            <span className={'bold-text'}>Extra Guest Fee:</span> {selectedCurrencySymbol}{convertCurrency(totalExtraGuestFees).toFixed(2)}
                        </div>
                        <div class='showRates' onClick={handleRateToggle}>
                            <span class={'bold-text underline-text'}>Nightly Rates Total:</span> {selectedCurrencySymbol}{convertCurrency(Number((nightlyRatesTotal).toFixed(2))).toFixed(2)}
                        </div>
                        <div className={isRateDisplay ? "displayShow" : 'displayHide'}>
                            <span className='text-sm'><ul id={'nightly-rates-ul'}>{nightlyRatesDisplay}</ul></span>
                        </div>
                        <div class='showFees' onClick={handleFeeToggle}>
                            <span className={'bold-text underline-text'}>Nightly Fees Total:</span> {selectedCurrencySymbol}{convertCurrency(Number((nightlyFeesTotal).toFixed(2))).toFixed(2)}
                        </div>
                        <div className={isFeeDisplay ? "displayShow" : 'displayHide'}>
                            <span className='text-sm'><ul id={'nightly-rates-ul'}>{nightlyFeesDisplay}</ul></span>
                        </div>
                        <div className='text-sm'>
                            <span className={'bold-text'}>Subtotal:</span> {selectedCurrencySymbol}{convertCurrency(quote.subTotal > 0 ? quote.subTotal : '').toFixed(2)}
                        </div>
                        <div className='text-sm'>
                            <span className={'bold-text'}>Tax:</span> {selectedCurrencySymbol}{convertCurrency(quote.taxAmount > 0 ? quote.taxAmount : '').toFixed(2)}
                        </div>
                        <div className='text-sm'>
                            <span className={'bold-text'}>Total:</span> {selectedCurrencySymbol}{convertCurrency(quote.total > 0 ? quote.total : '').toFixed(2)}
                        </div>
                        <div className='text-sm'>
                            <span className={'bold-text'}>Deposit (paid separately):</span> {selectedCurrencySymbol}{convertCurrency(quote.securityDeposit > 0 ? quote.securityDeposit : '').toFixed(2)}</div>
                    </div>
                </Col>
                    : ''
                }
            </Row>

        </>
    );
}

export default BookNow;