import React, { useEffect, useState } from "react";
import { Simple } from '../../components/layouts';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Property, Loading, SearchFilter, PriceRange, MoreFilters } from '../../components/common';
import { useSelector, useDispatch } from 'react-redux';
import { propertyDataSelectors, propertyDataActions } from '../../store/slices/propertyData';
import image from '../../assets/1503.png';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { getCurrencyRate } from '../../store/slices/propertyData/selectors'
import PropertySkeleton from "./PropertySkeleton";

const Home = () => {
	const dispatch = useDispatch();
	const [activePage, setActivePage] = useState(0);
	const [maxResults, setMaxResults] = useState(15);
	const properties = useSelector(propertyDataSelectors.getProperties);
	const filters = useSelector(propertyDataSelectors.getFilters);
	const currencyRate = useSelector(getCurrencyRate);
	const [initialLoad, setInitialLoad] = useState(true);


	const getFilters = (offset = 0) => {
		let Filters = JSON.parse(JSON.stringify(filters));
		if (maxResults !== 'all') {
			Filters['maxresults'] = maxResults;
		}
		Filters['offset'] = offset;
		return Filters;
	}

	useEffect(() => {
		const fetchProperties = async () => {
		  await dispatch(propertyDataActions.propertiesData(getFilters()));
		  setInitialLoad(false);
		};
	
		fetchProperties();
	  }, [dispatch]);// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(propertyDataActions.propertiesData(maxResults !== 'all' ? getFilters(maxResults * activePage) : getFilters()));
	}, [activePage, maxResults]); // eslint-disable-line react-hooks/exhaustive-deps

	const isLoading = useSelector(propertyDataSelectors.isLoading);

	const search = () => {
		setActivePage(0);
		dispatch(propertyDataActions.propertiesData(getFilters()));
	}

	const clearFilters = () => {
		setActivePage(0);
		dispatch(propertyDataActions.filters({}));
		dispatch(propertyDataActions.propertiesData({ offset: 0, maxresults: maxResults }));
	}

	const renderPropertySkeletons = () => {
		const skeletons = [];
		const count = maxResults === 'all' ? 15 : parseInt(maxResults, 10);
	  
		for (let i = 0; i < count; i++) {
		  skeletons.push(
			<Col key={i} lg={4} md={6} sm={6} xs={12}>
			  <PropertySkeleton />
			</Col>
		  );
		}
	  
		return skeletons;
	  };

	return (
		<Simple src={image} alt='logo' currencyRate={currencyRate}>
			<Container className="f-property pt-5">

				<Row>
					<Col sm={12}>
						<Breadcrumb>
							<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
							<Breadcrumb.Item active>Search Result</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
					<Col sm={9} xs={12}>
						<h1 className="border-top pt-2"> All Vacation Rentals </h1>
						<SearchFilter search={search} />
						<Row>
      {initialLoad ? (
        <Loading isLoading={true} isLoadingMessage="Loading properties..." />
      ) : (
        <>
          <Loading isLoading={isLoading} isLoadingMessage="Loading properties..." />
          {!isLoading && properties.results && properties.results.length === 0 ? (
            <div className="w-100 text-center py-5">
              <span>
                <strong>No properties found.</strong>
              </span>
            </div>
          ) : null}
          {isLoading
            ? renderPropertySkeletons()
            : properties.results &&
              properties.results.map((property) => (
          <Col lg={4} md={6} sm={6} xs={12}>
            <Property
              property={property}
              weekdayRate={(property.weekdayRate * currencyRate).toFixed(2)}
              bedrooms={property.bedrooms}
              beds={property.beds}
              sleeps={property.sleeps}
              bathrooms={property.bathrooms}
              img={property.image.mediumUrl}
              title={property.property_name}
              desc={property.city}
              property_id={property.id}
            />
          </Col>
        ))}
		</>
	  )}
  </Row>
						<Row className="mt-5">
							<Col lg={2} md={4} sm={4} xs={4}>
								<label>Items Per Page</label>
							</Col>
						</Row>
						<Row className="mb-5">
							<Col lg={2} md={6} sm={6} xs={6}>
								<select value={maxResults} onChange={(e) => { setActivePage(0); setMaxResults(e.target.value) }} className="dropdown form-control">
									<option value={15}>15</option>
									<option value={50}>50</option>
									<option value={'all'}>All</option>
								</select>
							</Col>
							{maxResults !== 'all' ? <Col lg={10} md={6} sm={6} xs={6} className="d-flex align-items-center">
								{activePage > 0 ? <span onClick={(e) => setActivePage(activePage - 1)} className="cursor-pointer text-primary">{'< '}Prev </span> : <span>{'< '}Prev </span>}
								&nbsp;&nbsp;|&nbsp;&nbsp;
								{properties.results && properties.results.length > 0 && properties.results.length === maxResults && !isLoading ? <span onClick={(e) => setActivePage(activePage + 1)} className="cursor-pointer text-primary"> Next {' >'}</span> : <span> Next {' >'}</span>}
							</Col> : null}
						</Row>
					</Col>
					<Col sm={3} xs={12}>
						<PriceRange currencyRate={currencyRate} />
						<MoreFilters search={search} clearFilters={clearFilters} />
					</Col>
				</Row>
			</Container>
		</Simple>
	)
};

export default Home;