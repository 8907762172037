import React, { useEffect, useState } from "react";
import { Simple } from '../../components/layouts';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import { useSelector, useDispatch } from 'react-redux';
import { Loading, Property, BookNow, SendInquiryForm, ImageBanner, ImageGallery } from '../../components/common';
import { propertyDataSelectors, propertyDataActions } from '../../store/slices/propertyData';
import image from '../../assets/1503.png';
import GoogleMapReact from 'google-map-react';
import { IoIosBed } from "react-icons/io";
import './index.scss';
import Modal from 'react-bootstrap/Modal';
import { getCurrencyRate } from '../../store/slices/propertyData/selectors'
import { FiGrid } from 'react-icons/fi';


const Home = (props) => {
	const dispatch = useDispatch();
	const activeId = props.match.params.id;
	const property = useSelector(propertyDataSelectors.getProperty);
	const properties = useSelector(propertyDataSelectors.getProperties);
	const isLoading = useSelector(propertyDataSelectors.isLoading);
	const [smShow, setSmShow] = useState(false);
	const conversionRate = useSelector(getCurrencyRate);
	const selectedCurrencySymbol = useSelector(propertyDataSelectors.getSelectedCurrencySymbol);
	const [showFullDescription, setShowFullDescription] = useState(false);
	const [showFullAmenities, setShowFullAmenities] = useState(false);
	const [showGallery, setShowGallery] = useState(false);




	useEffect(() => {
		dispatch(propertyDataActions.propertyDetails({ id: activeId }));
		dispatch(propertyDataActions.propertiesData({ maxresults: 3 }));
	}, [dispatch, activeId]);

	useEffect(() => {
		if (property.error) {
			dispatch(propertyDataActions.propertyDetails({}));
			props.history.push('/not-found');
		}
	}, [property]);// eslint-disable-line react-hooks/exhaustive-deps


	return (
		<div>
		  <Simple src={image} alt='logo' currencyRate={conversionRate}>
			<Container>
			  <Row>
				<Col className="d-flex align-items-center justify-content-center">
				  <Loading isLoading={isLoading} />
				</Col>
			  </Row>
			  {!isLoading && property.property_name && (
				<>
				<Row className="justify-content-center">
              <Col sm={10} className="mt-4">
                <div className="mt-4" style={{ paddingTop: '50px' }}>
                  <h2>{property.property_name}</h2>
                  <p>★ {property.property_city}{property.property_state ? `, ${property.property_state}` : null}, {property.property_country}</p>
                </div>
              </Col>
            </Row>
				<Row className="justify-content-center">
					<Col sm={10}>
						<div style={{position: 'relative'}}>
							<ImageBanner 
							primarySrc={property.property_images ? property.property_images[Object.keys(property.property_images)[0]]['url'] : null} 
							secondarySrcs={property.property_images ? Object.keys(property.property_images).slice(1, 5).map(el => property.property_images[el]['url']) : []}
							/>

								<button 
									onClick={() => setShowGallery(true)} 
									className="see-all-images-button"
									style={{ display: 'flex', alignItems: 'center' }}
								>
									<FiGrid style={{ marginRight: '8px' }} />
									See all images
								</button>
							</div>
						</Col>
					</Row>


						<Modal show={showGallery} onHide={() => setShowGallery(false)}>
							<Modal.Header closeButton>
								<Modal.Title>Image Gallery</Modal.Title>
							</Modal.Header>
							<ImageGallery property={property} />
							<Modal.Footer>
								<Button variant="secondary" onClick={() => setShowGallery(false)}>
									Close
								</Button>
							</Modal.Footer>
						</Modal>
				  <Row className="justify-content-center">
					<Col sm={6} className="mt-4">
					  {/* Property Description */}
					  {property.property_description ? (
					  <aside className="aside_widget pb-4">
						<h4 className="heading pb-3 mb-4">About the Space</h4>
						<p>
						{showFullDescription
						  ? property.property_description
						  : `${property.property_description.slice(0, 500)}...`}
						</p>
						<Button variant="link" onClick={() => setShowFullDescription(!showFullDescription)}>
						{showFullDescription ? "Show Less" : "Show More"}
						</Button>
					  </aside>
					  ) : null}
					  {/* Other property details go here... */}
					  <aside className="aside_widget mb-5">
											<h5 className="heading pb-3 mb-2">Quick Summary</h5>
											<ul className="mt-2">
												<li className="row pt-2"><strong className="col-6">Property type:</strong><span>{property.property_type}</span></li>
												<li className="row pt-2"><strong className="col-6">Size: </strong><span>{property.property_area} sq.ft.</span></li>
												<li className="row pt-2"><strong className="col-6">Bedrooms:</strong><span>{property.property_bedroom_count}</span></li>
												<li className="row pt-2"><strong className="col-6">Beds:</strong><span>{property.property_bed_count}</span></li>
												<li className="row pt-2"><strong className="col-6">Bathrooms:</strong><span>{property.property_fullbath_count}</span></li>
												<li className="row pt-2"><strong className="col-6">Half bathrooms:</strong><span>{property.property_halfbath_count}</span></li>
												<li className="row pt-2"><strong className="col-6"> Security Deposit:</strong><span>${property.property_deposit && (property.property_deposit * conversionRate).toFixed(2).replace(/\.00$/, '')}</span></li>
											</ul>
										</aside>
										{/* <aside className="aside_widget mb-5">
											<h5 className="heading pb-3 mb-2">Suitability</h5>
											<ul >
												<li className="row pt-2"><strong className="col-6">Smoking Allowed</strong><span>No</span></li>
												<li className="row pt-2"><strong className="col-6">Pets Allowed </strong><span>Yes</span></li>
												<li className="row pt-2"><strong className="col-6">Smoking Allowed</strong><span>No</span></li>
												<li className="row pt-2"><strong className="col-6">Pets Allowed </strong><span>Yes</span></li>
												<li className="row pt-2"><strong className="col-6">Smoking Allowed</strong><span>No</span></li>
												<li className="row pt-2"><strong className="col-6">Pets Allowed </strong><span>Yes</span></li>
												<li className="row pt-2"><strong className="col-6">Smoking Allowed</strong><span>No</span></li>
												<li className="row pt-2"><strong className="col-6">Pets Allowed </strong><span>Yes</span></li>
											</ul>
										</aside> */}
										{/* <aside className="aside_widget mb-5">
											<h5 className="heading pb-3 mb-2">Extended Stay Discount</h5>
											<p className="mb-0"><strong>A discount may be applied to a stay of 7 nights or more.</strong></p>
										</aside> */}
										{property.cancellation_policy && Object.keys(property.cancellation_policy).length > 0 ? <aside className="aside_widget mb-5">
											<h5 className="heading pb-3 mb-4">Cancellation Policy</h5>
											<div className="bg-light border px-3 py-3">
												<ul className="cancelpolicy">
													{property.cancellation_policy && Object.keys(property.cancellation_policy).map(el => {
														return (
															<li className="pb-3">
																For bookings cancelled at least <strong>{property.cancellation_policy[el]['withinDays']} day(s)</strong> before the start of stay, the guest is charged a {property.cancellation_policy[el]['amount']}% cancellation fee.
															</li>
														)
													})}
												</ul>
											</div>
										</aside> : null}
	
										{property.property_amenity && Object.keys(property.property_amenity).length > 0 ? (
												<aside className="aside_widget pb-4">
													<h4 className="heading pb-3 mb-4">Amenities</h4>
													<ul className="mt-4 list">
													{property.property_amenity &&
														Object.keys(property.property_amenity)
														.slice(0, showFullAmenities ? undefined : 3)
														.map((el) => {
															const amenityCategory = Object.keys(property.property_amenity[el])[0];
															const amenityItems = property.property_amenity[el][amenityCategory];
	
															return (
															<li className="row pt-2" key={amenityCategory}>
																<strong className="col-12">{amenityCategory}:</strong>
																<ul className="col-12">
																{Object.keys(amenityItems).map((chi) => {
																	return (
																	<li key={chi}>
																		{amenityItems[chi]["name"]}
																	</li>
																	);
																})}
																</ul>
															</li>
															);
														})}
													</ul>
													<Button variant="link" onClick={() => setShowFullAmenities(!showFullAmenities)}>
													{showFullAmenities ? "Show Less" : "Show All Amenities"}
													</Button>
												</aside>
						  ) : null}
						  
										{property.property_bedroom && Object.keys(property.property_bedroom).length > 0 ? <aside className="aside_widget pb-4">
											<h4 className="heading pb-3 mb-4">Sleeping Arrangements:</h4>
	
											<Carousel
												swipeable={true}
												responsive={{
													desktop: {
														breakpoint: { max: 3000, min: 1024 },
														items: 2,
														slidesToSlide: 1
													},
													tablet: {
														breakpoint: { max: 1024, min: 464 },
														items: 1,
														slidesToSlide: 1
													},
													mobile: {
														breakpoint: { max: 464, min: 0 },
														items: 1,
														slidesToSlide: 1
													}
												}}
												infinite={true}
												autoPlay={true}
												arrows={false}
												showDots={true}
												keyBoardControl={false}
												transitionDuration={500}
												containerClass="carousel-container pb-4"
												removeArrowOnDeviceType={[]}
												itemClass="p-1"
											>
												{property.property_bedroom && Object.keys(property.property_bedroom).map(el => {
													return (
														<div className="p-3 rooms_card">
															<IoIosBed size={40} />
															<p className="mb-1"><strong>{property.property_bedroom[el]["name"]}</strong></p>
															<p className="mb-0">{property.property_bedroom[el]["description"]}</p>
															{property.property_bedroom[el]["type"] && Object.keys(property.property_bedroom[el]["type"]).map(typ => {
																return (
																	<span>{property.property_bedroom[el]["type"][typ]['quantity']} {property.property_bedroom[el]["type"][typ]['type']} Bed</span>
																)
															})}
														</div>
													)
												})}
											</Carousel>
										</aside> : null}
	
										{property.property_latitude && property.property_longitude ? <aside className="aside_widget pb-4">
											<h4 className="heading pb-3 mb-4">Location</h4>
											<p><strong>Location map</strong></p>
											<div className="w-100 mb-2" style={{ height: '300px' }}>
												<GoogleMapReact
													bootstrapURLKeys={{ key: 'AIzaSyAgp05Y6LGKd3y2ZLJ2XNcVxNq5DJwAD8U' }}
													defaultCenter={{
														lat: property.property_latitude,
														lng: property.property_longitude
													}}
													defaultZoom={15}
												>
													<div className='map_loc_container'>
														&nbsp;
													</div>
												</GoogleMapReact>
											</div>
										</aside> : null}
	
										{property.property_rule ? <aside className="aside_widget pb-4">
											<h4 className="heading pb-3 mb-4">House rules</h4>
											<p>{property.property_rule}</p>
										</aside> : null}
										
					</Col>
					<Col sm={4} className="mt-2">
					  <div className="booknow-sidebar">
						<aside className="aside_widget pb-4 book_sidebar">
						  <div className="book_sidebar_sticky">
							<span>From</span>
							<h4 className="heading pb-3 mb-4">{selectedCurrencySymbol}{(property.available_baserate_weekday * conversionRate).toFixed(2)}</h4>
							<BookNow activeId={activeId} property={property} conversionRate={conversionRate} />
							<Button onClick={() => setSmShow(true)} className="w-100  mb-2" variant="outline-secondary">Send Inquiry</Button>
						  </div>
						</aside>
					  </div>
					</Col>
				  </Row>
				  {/* Other Components Go Here */}
				  <Row className="mb-4">
									<Col sm={9} className="mt-3">
										<h4>Other Properties</h4>
									</Col>
	
									{properties.results && properties.results.map((property, index) => {
										return (<Col sm={4}><Property property={property}  weekdayRate={(property.weekdayRate * conversionRate).toFixed(2)} bedrooms={property.bedrooms} beds={property.beds} sleeps={property.sleeps} bathrooms={property.bathrooms} img={property.image.mediumUrl} title={property.property_name} desc={property.city} property_id={property.id} /></Col>)
									})}
	
								</Row>
				</>
			  )}
			</Container>
		  </Simple>
		  <Modal size="lg" show={smShow} onHide={() => setSmShow(false)} aria-labelledby="Send-Inquiry">
			<Modal.Header closeButton>
			  <Modal.Title id="Send-Inquiry" className="text-center d-block w-100">
				Ask a question about the rental
			  </Modal.Title>
			</Modal.Header>
			<Modal.Body >
			  <SendInquiryForm property_id={property.property_id} />
			</Modal.Body>
		  </Modal>
		</div>
	  );
	  
	}
	

export default Home;