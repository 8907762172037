import { useEffect, useRef } from 'react';
import styles from './GoogleTranslate.module.css';

const GoogleTranslate = () => {
  const translateElementRef = useRef(null);

  useEffect(() => {
    const googleTranslateScript = document.createElement('script');
    googleTranslateScript.type = 'text/javascript';

    googleTranslateScript.innerHTML = `
      function googleTranslateElementInit() {
        new google.translate.TranslateElement({
          pageLanguage: 'en',
          includedLanguages: 'pt,es',
          layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: false
        }, 'google_translate_element');
      }
    `;
    document.body.appendChild(googleTranslateScript);

    const googleTranslateAPI = document.createElement('script');
    googleTranslateAPI.type = 'text/javascript';
    googleTranslateAPI.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    document.body.appendChild(googleTranslateAPI);

    return () => {
      document.body.removeChild(googleTranslateScript);
      document.body.removeChild(googleTranslateAPI);
    };
  }, []);

  const handleClick = () => {
    translateElementRef.current.querySelector('.goog-te-gadget-simple').click();
  };

  return (
    <>
      <div ref={translateElementRef} id="google_translate_element" style={{ display: 'none' }}></div>
      <button className={styles.CustomTranslateButton} onClick={handleClick}>Translate</button>
    </>
  );
};

export default GoogleTranslate;
