import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { propertyDataSelectors } from '../../../store/slices/propertyData';
import React, { useEffect, useState } from "react";
import config from '../../../config';
import axios from 'axios';

const Pages = (props) => {
	const manager = useSelector(propertyDataSelectors.getPropertyManager);
	const [pages, setPages] = useState([]);

	const getCompanyPages = async (id) => {
		try {
			const { endPoint, baseURL, token } = config.pages;
			const response = await axios.get(`${baseURL}?endpoint=${endPoint}${id}`, { headers: { "Authorization": `Bearer ${token}` } });
			setPages(response.data);
		} catch (err) {
		}
	}

	useEffect(() => {
		if (manager.id) {
			getCompanyPages(manager.id);
		}
	}, [manager]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Link className={window.location.pathname === '/about-us' ? "nav-link nav_active" : "nav-link"} to="/about-us">About Us</Link>
			<Link className={window.location.pathname === '/contact-us' ? "nav-link nav_active" : "nav-link"} to="/contact-us">Contact Us</Link>

			{pages.map(el => {
				el = el.Page;
				return (
					<Link className={window.location.pathname === `/${el.slug}` ? "nav-link nav_active" : "nav-link"} to={`/${el.slug}`}>{el.title}</Link>
				)
			})}
		</>
	);
};


export default Pages;