import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { propertyDataSelectors } from '../../../store/slices/propertyData';
import './logo.scss';

const Logo = (props) => {
	const { alt, customclass } = props;
	const manager = useSelector(propertyDataSelectors.getPropertyManager);

	if (manager.customSitesDetails && manager.customSitesDetails.bannerTitle) {
		return <span className={((manager.customSitesDetails.headColor === "ffffff" || manager.customSitesDetails.headColor === "FFFFFF" || manager.customSitesDetails.headColor === "fff" || manager.customSitesDetails.headColor === "FFF")? "blackText " : "")+(customclass ? customclass : '')}>{manager.customSitesDetails.bannerTitle}</span>
	} else if (manager.customSitesDetails && manager.customSitesDetails.customLogo) {
		return (
			<img className={customclass ? customclass : ''} src={manager.customSitesDetails.customLogo['fullImage']} alt={alt} />
		);
	} else if (manager.logo && manager.logo['Digital Logo']) {
		return (
			<img className={customclass ? customclass : ''} src={manager.logo['Digital Logo']['url']} alt={alt} />
		);
	}
	return null
};

Logo.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired
}


export default Logo;