import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { propertyDataSelectors } from '../../../store/slices/propertyData';
import { Link } from 'react-router-dom';

const RecentViews = ({ currencyRate }) => {
    const properties = useSelector(propertyDataSelectors.getAllProperties);
    const [recents, setRecents] = useState([]);
    const selectedCurrencySymbol = useSelector(propertyDataSelectors.getSelectedCurrencySymbol);

    useEffect(() => {
        if (properties.results && properties.results.length > 0) {
            let rece = [];
            if (localStorage.getItem('recents')) {
                rece = JSON.parse(localStorage.getItem('recents'));
            } else {
                rece = properties.results.filter((el, index) => index < 2);
            }
            setRecents(rece);
        }
    }, [properties]);

    return (
        <div className="recent-post">
            <ul>
                {recents.map(el => {
                    const defaultRate = el.weekdayRate || el.startingRate;
                    const convertedRate = !isNaN(currencyRate) ? (defaultRate * currencyRate).toFixed(2) : defaultRate;
                    return (
                        <li key={el.property_name || el.name}>
                            <Link className="d-flex" to={`/property/details/${el.id}`}>
                                <div className="recent-post-thumb">
                                    <Image src={el.image ? el.image.mediumUrl : el.imageMedium} height="76" />
                                </div>
                                <div className="recent-post-content ml-2">
                                    <h5 className="text-dark mb-0">{el.property_name || el.name}</h5>
                                    <p className="text-gray mb-1 mt-2">{el.city}</p>
                                    <div className="post-price d-inline-block px-2 py-1 mt-1">
                                    From <span>{selectedCurrencySymbol}{convertedRate}</span>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}

export default RecentViews;