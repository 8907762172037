import React, { useState, useEffect } from "react";
import { Simple } from '../../components/layouts';
import { Row, Col, Container, CardDeck, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import image from '../../assets/1503.png';
import { useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import "./payment.scss";
import { SiVisa } from "react-icons/si";
import { SiMastercard } from "react-icons/si";
import { FaCcDiscover } from "react-icons/fa";
import { GrAmex } from "react-icons/gr";
import { SiJcb } from "react-icons/si";
import { FaCcDinersClub } from "react-icons/fa";
// import { IoCloseOutline } from "react-icons/io5";
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import { propertyDataSelectors, propertyDataActions } from '../../store/slices/propertyData';
import propertyService from '../../services/propertyService';
import * as moment from 'moment';
import loader from '../../assets/loading.svg';
import { getCurrencyRate } from '../../store/slices/propertyData/selectors'


const Home = (props) => {
	const dispatch = useDispatch();
	const query = new URLSearchParams(useLocation().search);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const property = useSelector(propertyDataSelectors.getProperty);
	const [smShow, setSmShow] = useState(false);
	const [policyShow, setpolicyShow] = useState(false);
	const [depositShow, setdepositShow] = useState(false);
	const [countries, setCountries] = useState([]);
	const [states, setStates] = useState([]);
	const [propertyData, setPropertyData] = useState({});
	const currencyRate = useSelector(getCurrencyRate);
	const selectedCurrencySymbol = useSelector(propertyDataSelectors.getSelectedCurrencySymbol);
	const [values, setValues] = useState({
		address: "",
		amount: "",
		bookingOrigin: "2",
		cardNumber: "",
		card_cvc: "",
		card_expirationDate: "",
		city: "",
		country: "US",
		endAt: "",
		firstName: "",
		guestCount: "",
		invoiceTerm: "1",
		lastName: "",
		nameAsAppearsOnCard: "",
		notes: "",
		pets: "",
		phone: "",
		postalCode: "",
		property: "",
		startAt: "",
		state: "",
		stripeEmail: "",
		card_year: "",
		card_month: ""
	});

	const convertCurrency = (amount, rate) => {
		return (amount * rate).toFixed(2).replace(/\.00$/, '');
	  };

	const validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	const validate = (obj) => {
		let status = true;
		Object.keys(obj).forEach(key => {
			if (['address', 'cardNumber', 'card_cvc', 'card_year', 'card_month', 'state', 'country', 'city', 'firstName', 'phone', 'stripeEmail', 'lastName', 'nameAsAppearsOnCard', 'postalCode'].includes(key)) {
				if (!obj[key]) {
					status = false;
					document.getElementById(key).classList.add("is-invalid");
				} else if (key === 'cardNumber' && obj[key].length < 16) {
					status = false;
					document.getElementById(key).classList.add("is-invalid");
				} else if (key === 'card_cvc' && obj[key].length < 3) {
					status = false;
					document.getElementById(key).classList.add("is-invalid");
				} else if (key === 'stripeEmail' && !validateEmail(obj[key])) {
					status = false;
					document.getElementById(key).classList.add("is-invalid");
				}
			}
		});
		return status;
	}

	const handleSubmit = async () => {
		let obj = JSON.parse(JSON.stringify(values));
		if (!validate(obj)) {
			return
		}
		setLoading(true);
		obj['card_expirationDate'] = obj.card_month + ' / ' + obj.card_year;
		obj['amount'] = propertyData.total;
		obj['endAt'] = moment(query.get('endAt'), "MMM DD, YYYY").format('YYYY-MM-DD');
		obj['startAt'] = moment(query.get('startAt'), "MMM DD, YYYY").format('YYYY-MM-DD');
		obj['guestCount'] = query.get('guests');
		obj['property'] = property.property_id;
		delete obj.card_year;
		delete obj.card_month;
		try {
			let data = await propertyService.makeReservation(obj);
			props.history.push('/Confirmation', { propertyData: property, startAt: query.get('startAt'), endAt: query.get('endAt'), guestCount: query.get('guests'), bookingData: data, quote: propertyData });
		} catch (err) {
			setError('Cannot book property, please try again later.');
		}
		setLoading(false);
	}

	const checkAvailability = async () => {
		let data = await propertyService.getPropertyAvailability({ propertyId: query.get('pid'), startAt: moment(query.get('startAt'), "MMM DD, YYYY").format('YYYY-MM-DD'), endAt: moment(query.get('endAt'), "MMM DD, YYYY").format('YYYY-MM-DD'), guestCount: query.get('guests') , customSite: 1});
		console.log(data)
		setPropertyData(data.quote);
	}

	const changeValues = (value, key) => {
		if (value && key === 'cardNumber' && (value.length > 16 || !/^\d+$/.test(value))) {
			return
		} else if (value && key === 'card_cvc' && (value.length > 4 || !/^\d+$/.test(value))) {
			return
		}
		document.getElementById(key).classList.remove("is-invalid");
		let data = JSON.parse(JSON.stringify(values));
		data[key] = value;
		setValues(data);
	}

	const getCountries = async () => {
		let data = await propertyService.getCountries();
		setCountries(data);
	}

	const getStates = async (code) => {
		let data = await propertyService.getStates({ state: code });
		setStates(data);
	}

	const getTotal = (obj) => {
		let total = 0;
		Object.keys(obj).forEach(el => {
			total += obj[el];
		});
		return total.toFixed(2).replace(/\.00$/, '');
	}

	useEffect(() => {
		dispatch(propertyDataActions.propertyDetails({ id: query.get('pid') }));
		checkAvailability();
		getCountries();
		getStates('US');
		console.log(property);
	}, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getStates(values.country);
	}, [values.country]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (property.error) {
			dispatch(propertyDataActions.propertyDetails({}));
			props.history.push('/not-found');
		}
	}, [property]);// eslint-disable-line react-hooks/exhaustive-deps


	return (
		<Simple src={image} alt='logo' currencyRate={currencyRate}>
			<Container className="mt-5 mb-4">
				<h1 style={{ marginTop: '8rem' }}> Payment </h1>
				<Row className="mt-4 ">
					<Col sm={7}>
						<Form>
							<div className="payment payment-info">
								<h4 >Your payment info</h4>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>Debit/Credit Card Number<sup>*</sup></Form.Label>
										<Form.Control value={values.cardNumber} type="text" id="cardNumber" onChange={(e) => changeValues(e.target.value, 'cardNumber')} />
									</Form.Group>
									<Form.Group as={Col}>
										<div className="payment-card row">
											<span class="col"><SiVisa /></span>
											<span class="col"><SiMastercard /></span>
											<span class="col"><FaCcDiscover /></span>
											<span class="col"><GrAmex /></span>
											<span class="col"><SiJcb /></span>
											<span class="col"><FaCcDinersClub /></span>
										</div>
									</Form.Group>
								</Form.Row>
								<Form.Group>
									<Form.Label>Name of Card Holder<sup>*</sup></Form.Label>
									<Form.Control type="text" id="nameAsAppearsOnCard" onChange={(e) => changeValues(e.target.value, 'nameAsAppearsOnCard')} />
								</Form.Group>

								<Form.Row>
									<Form.Group as={Col} controlId=" ">
										<Form.Label>Expiration Date<sup>*</sup></Form.Label>
										<Row>
											<Col>
												<Form.Control onChange={(e) => changeValues(e.target.value, 'card_month')} as="select" id="card_month" form-control>
													<option value="">mm</option>
													<option value="01">01</option>
													<option value="02">02</option>
													<option value="03">03</option>
													<option value="04">04</option>
													<option value="05">05</option>
													<option value="06">06</option>
													<option value="07">07</option>
													<option value="08">08</option>
													<option value="09">09</option>
													<option value="10">10</option>
													<option value="11">11</option>
													<option value="12">12</option>
												</Form.Control>
											</Col>
											<Col>
												<Form.Control onChange={(e) => changeValues(e.target.value, 'card_year')} as="select" id="card_year" form-control>
													<option value="">yyyy</option>
													<option value="2023">2023</option>
													<option value="2024">2024</option>
													<option value="2025">2025</option>
													<option value="2026">2026</option>
													<option value="2027">2027</option>
													<option value="2028">2028</option>
													<option value="2028">2028</option>
													<option value="2029">2029</option>
													<option value="2030">2030</option>
												</Form.Control>
											</Col>
										</Row>
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>Security Code (3 or 4 digits)<sup>*</sup></Form.Label>
										<Form.Control value={values.card_cvc} type="text" id="card_cvc" onChange={(e) => changeValues(e.target.value, 'card_cvc')} />
									</Form.Group>
								</Form.Row>

							</div>
							<div className="payment billing-info">
								<h4 >Your billing info</h4>

								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>First Name<sup>*</sup></Form.Label>
										<Form.Control type="text" id="firstName" onChange={(e) => changeValues(e.target.value, 'firstName')} />
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>Last Name<sup>*</sup></Form.Label>
										<Form.Control type="text" id="lastName" onChange={(e) => changeValues(e.target.value, 'lastName')} />
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>Phone Number<sup>*</sup></Form.Label>
										<Form.Control type="number" id="phone" onChange={(e) => changeValues(e.target.value, 'phone')} />
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>Street Address<sup>*</sup></Form.Label>
										<Form.Control type="text" id="address" onChange={(e) => changeValues(e.target.value, 'address')} />
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>City<sup>*</sup></Form.Label>
										<Form.Control type="text" id="city" onChange={(e) => changeValues(e.target.value, 'city')} />
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>State<sup>*</sup></Form.Label>
										<Form.Control as="select" id="state" form-control onChange={(e) => changeValues(e.target.value, 'state')}>
											<option value={''}>Select State</option>
											{states.map(el => {
												return (
													<option value={el.id}>{el.name}</option>
												)
											})}
										</Form.Control>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>Postal Code<sup>*</sup></Form.Label>
										<Form.Control type="text" id="postalCode" onChange={(e) => changeValues(e.target.value, 'postalCode')} />
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>Country<sup>*</sup></Form.Label>
										<Form.Control value={values.country} id="country" as="select" form-control onChange={(e) => changeValues(e.target.value, 'country')}>
											{countries.map(el => {
												return (
													<option value={el.fipsCode}>{el.name}</option>
												)
											})}
										</Form.Control>
									</Form.Group>
								</Form.Row>
							</div>
							<div className="payment confirm-info">
								<h4 >Where should we send your confirmation?</h4>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>Email<sup>*</sup></Form.Label>
										<Form.Control type="text" id="stripeEmail" onChange={(e) => changeValues(e.target.value, 'stripeEmail')} />
									</Form.Group>
									{/* <Form.Group as={Col}>
										<Form.Check type="checkbox" id="temp" label="Send me emails with news and promos" />
									</Form.Group> */}
								</Form.Row>
							</div>
							<div className="payment confirm-info">
								<h4 >Do you have any questions?</h4>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>Your message (Optional)</Form.Label>
										<Form.Control id="notes" as="textarea" rows={3} onChange={(e) => changeValues(e.target.value, 'notes')} />
									</Form.Group>
								</Form.Row>
								<hr />
								<div className="text-center">
									<p>By clicking on "Book It Now" you agree to pay the total of{" "}
  <strong>{selectedCurrencySymbol}{convertCurrency(propertyData.total, currencyRate)}</strong> and agree to the <a rel="noreferrer" target="_blank" href="https://lodgable.com/legal/guest-terms/">Terms of Service</a>, <span className="cursor-pointer text-primary" onClick={() => setSmShow(true)}>House Rules</span>, <span className="cursor-pointer text-primary" onClick={() => setpolicyShow(true)}>Cancellation Policies</span>.</p>
									{/* <input type="button" className="btn btn-primary mb-3" value="Book It Now" onClick={() => handleSubmit()} /> */}
									<div className="w-100 py-2">
										<span className="text-danger">{error}</span>
									</div>
									<Button variant="primary" disabled={loading} className="btn btn-primary" onClick={() => handleSubmit()}>
										{loading ? <img style={{ width: '20%' }} src={loader} alt='loading' /> : 'Book It Now'}
									</Button>
									<p><strong>Clicking on "Book It Now" will confirm your reservation and charge your payment method.</strong></p>
								</div>
							</div>
						</Form>
					</Col>
					<Col sm={5}>
						<CardDeck className="prod_details">
							<Card>
								<Card.Img variant="top" src={property.property_images ? property.property_images['1']['url'] : null} />
								<Card.Body className="pl-5 pr-5">
									<Card.Text>
										<div className="card-info">
											<h5>{property.property_name}</h5>
											<p className="text-muted">{property.property_city}, {property.property_country}</p>
											<hr />
											<ul class="mt-2">
												<li className="row pt-2"><strong className="col-4">Check In:</strong><span>{query.get('startAt')} at 03:00pm</span></li>
												<li className="row pt-2"><strong className="col-4">Check Out:</strong><span>{query.get('endAt')} at 11:00pm</span></li>
												<li className="row pt-2"><strong className="col-4">Guests:</strong><span>{query.get('guests')}</span></li>
											</ul>
											<hr />
											<ul class="mt-2">
												<li className="row pt-2"><span className="col-8">House Rules:</span><span className="col-4 text-right text-primary cursor-pointer"><span onClick={() => setSmShow(true)}>View</span> </span></li>
												<li className="row pt-2"><span className="col-8">Cancellation Policy:</span><span className="col-4 text-right text-primary cursor-pointer"><span onClick={() => setpolicyShow(true)}>View</span> </span></li>
												<li className="row pt-2"><span className="col-8">Security Deposit:</span><span className="col-4 text-right text-primary"> <span>{selectedCurrencySymbol}{convertCurrency(propertyData.securityDeposit, currencyRate)}</span></span></li>
											</ul>
											<hr />
											<Accordion defaultActiveKey="-1">
												<ul class="mt-2 accordion_list">								
													<li className="w-100 pt-2">
														{propertyData.rates && Object.keys(propertyData.rates).length > 0 ? <Accordion.Toggle variant="link" eventKey="0">
															<strong className="col-8 pl-0">{propertyData.rates && Object.keys(propertyData.rates).length} Nights:</strong><span className="col-4 text-right text-primary pr-0">
															{selectedCurrencySymbol}{convertCurrency(getTotal(propertyData.rates), currencyRate)}
																</span>

														</Accordion.Toggle> : null}
														{propertyData.rates && Object.keys(propertyData.rates).length > 0 ? <Accordion.Collapse eventKey="0">
															<Card.Body>
																<Table>
																<tbody>
																{propertyData.rates &&
																	Object.keys(propertyData.rates).map((el) => {
																	return (
																		<tr>
																		<td>{el}</td>
																		<td>
																		{selectedCurrencySymbol}{convertCurrency(propertyData.rates[el], currencyRate)}
																		</td>
																		</tr>
																	);
																	})}
																</tbody>

																</Table>
															</Card.Body>
														</Accordion.Collapse> : null}
														{propertyData.extraGuestFees && Object.keys(propertyData.extraGuestFees).length > 0 ? <Accordion.Toggle variant="link" eventKey="1">
															<strong className="col-8 pl-0">Extra Guest Fee:</strong><span className="col-4 text-right text-primary pr-0">
															{selectedCurrencySymbol}{convertCurrency(getTotal(propertyData.extraGuestFees), currencyRate)}
																	</span>

														</Accordion.Toggle> : null}
														{propertyData.extraGuestFees && Object.keys(propertyData.extraGuestFees).length > 0 ? <Accordion.Collapse eventKey="1">
															<Card.Body>
																<Table>
																<tbody>
																{propertyData.extraGuestFees &&
																	Object.keys(propertyData.extraGuestFees).map((el) => {
																	return (
																		<tr>
																		<td>{el}</td>
																		<td>
																		{selectedCurrencySymbol}{convertCurrency(propertyData.extraGuestFees[el], currencyRate)}
																		</td>
																		</tr>
																	);
																	})}
																</tbody>

																</Table>
															</Card.Body>
														</Accordion.Collapse> : null}
																												{propertyData.fees && Object.keys(propertyData.fees).length > 0 ? (
														<Accordion.Toggle variant="link" eventKey="2">
															<strong className="col-8 pl-0">Fee:</strong>
															<span className="col-4 text-right text-primary pr-0">
															{selectedCurrencySymbol}{convertCurrency(getTotal(propertyData.fees), currencyRate)}
															</span>
														</Accordion.Toggle>
														) : null}
														{propertyData.fees && Object.keys(propertyData.fees).length > 0 ? (
														<Accordion.Collapse eventKey="2">
															<Card.Body>
															<Table>
																<tbody>
																{propertyData.fees &&
																	Object.keys(propertyData.fees).map((el) => {
																	return (
																		<tr>
																		<td>{el}</td>
																		<td>
																		{selectedCurrencySymbol}{convertCurrency(propertyData.fees[el], currencyRate)}
																		</td>
																		</tr>
																	);
																	})}
																</tbody>
															</Table>
															</Card.Body>
														</Accordion.Collapse>
														) : null}

													</li>
													{propertyData.cleaningFee ? (
  <li className="w-100 pt-2 d-flex">
    <strong className="col-8 pl-0">Cleaning Fee:</strong>
    <span className="col-4 text-right text-primary pr-0 align-self-end">
      {selectedCurrencySymbol}{convertCurrency(propertyData.cleaningFee, currencyRate)}
    </span>
  </li>
) : null}
<li className="w-100 pt-2 d-flex">
  <strong className="col-8 pl-0">Taxes:</strong>
  <span className="col-4 text-right text-primary pr-0 align-self-end">
    {selectedCurrencySymbol}{convertCurrency(propertyData.taxAmount, currencyRate)}
  </span>
</li>


												</ul>
											</Accordion>
											<hr />
											<div className="total-order text-center">
												<p><strong>No</strong> Surprises All charges included.</p>
												<h3>
  Total:{" "}
  <strong>
    {selectedCurrencySymbol}{convertCurrency(propertyData.total, currencyRate)}
  </strong>
</h3>

												<p className="text-muted">These prices do not include the security deposit since it will only be charged if a problem occurs.</p>
												<p className="text-muted small">All transactions are charged in USD, conversions are approximate and subject to change depending on actual market currency exchange rate.</p>
											</div>
										</div>
									</Card.Text>
								</Card.Body>
							</Card>
						</CardDeck>
					</Col>
				</Row> 
			</Container>
			<Modal size="lg" show={smShow} onHide={() => setSmShow(false)} >
				<Modal.Header closeButton>
					<Modal.Title className="text-center d-block w-100">
						House Rules
						</Modal.Title>
				</Modal.Header>
				<Modal.Body >
					{property.property_rule}
				</Modal.Body>
			</Modal>
			<Modal size="lg" show={policyShow} onHide={() => setpolicyShow(false)} >
				<Modal.Header closeButton>
					<Modal.Title className="text-center d-block w-100">
						Please review our cancellation policy
						</Modal.Title>
				</Modal.Header>
				<Modal.Body >
					<aside class="aside_widget mb-5">
						<div class="bg-light border px-3 py-3">
							<ul class="cancelpolicy">
								{property.cancellation_policy && Object.keys(property.cancellation_policy).map(el => {
									return (
										<li className="pb-3">For bookings cancelled at least <strong>{property.cancellation_policy[el]['withinDays']} day(s)</strong> before the start of stay, the guest is charged a <strong>{property.cancellation_policy[el]['amount']}% cancellation fee</strong>.</li>
									)
								})}
							</ul>
						</div>
					</aside>
				</Modal.Body>
			</Modal>
			<Modal size="lg" show={depositShow} onHide={() => setdepositShow(false)} >
				<Modal.Header closeButton>
					<Modal.Title className="text-center d-block w-100">
						Security Deposit
						</Modal.Title>
				</Modal.Header>
				<Modal.Body >
					Cras vehicula metus in nisi cursus tempor. Sed volutpat iaculis sapien, sed cursus ex interdum nec. Aliquam ultrices tincidunt velit, maximus gravida augue pharetra sit amet. Curabitur non nisl in ipsum viverra tempus nec sit amet elit. Duis consectetur accumsan ultrices. Nullam scelerisque lorem mi, sed tristique velit tincidunt eu. Cras dictum in sapien et ultricies. Ut vitae leo tristique, venenatis augue vel, semper turpis. Mauris quis ligula imperdiet lectus venenatis sollicitudin at sit amet arcu. Fusce laoreet nec ex ut fermentum.
					</Modal.Body>
			</Modal>
		</Simple >
	)
};

export default Home;