import React from 'react';
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { propertyDataSelectors } from '../../../store/slices/propertyData';

const SocialIcons = () => {
    const manager = useSelector(propertyDataSelectors.getPropertyManager);

    return (
        <div className="social_links">
            <ul>
                {manager.customSitesDetails && manager.customSitesDetails.sociallink && manager.customSitesDetails.sociallink.facebook ? <li><a rel="noreferrer" title="facebook" target="_blank" href={manager.customSitesDetails && manager.customSitesDetails.sociallink && manager.customSitesDetails.sociallink.facebook}><FaFacebookF /></a></li> : null}
                {manager.customSitesDetails && manager.customSitesDetails.sociallink && manager.customSitesDetails.sociallink.twitter ? <li><a rel="noreferrer" title="Twitter" target="_blank" href={manager.customSitesDetails && manager.customSitesDetails.sociallink && manager.customSitesDetails.sociallink.twitter}><FaTwitter /></a></li> : null}
                {manager.customSitesDetails && manager.customSitesDetails.sociallink && manager.customSitesDetails.sociallink.instagram ? <li><a rel="noreferrer" title="Instagram" target="_blank" href={manager.customSitesDetails && manager.customSitesDetails.sociallink && manager.customSitesDetails.sociallink.instagram}><FaInstagram /></a></li> : null}
                {manager.customSitesDetails && manager.customSitesDetails.sociallink && manager.customSitesDetails.sociallink.linkedin ? <li><a rel="noreferrer" title="Linkedin In" target="_blank" href={manager.customSitesDetails && manager.customSitesDetails.sociallink && manager.customSitesDetails.sociallink.linkedin}><FaLinkedinIn /></a></li> : null}
            </ul>
        </div>
    );
};

export default SocialIcons;