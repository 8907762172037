import React, { useEffect, useState, useCallback } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { propertyDataSelectors, propertyDataActions } from '../../../store/slices/propertyData';
import { useSelector, useDispatch } from 'react-redux';
import './SearchFilter.scss';
import * as moment from 'moment';
import propertyService from '../../../services/propertyService';
import CustomDropdown from './CustomDropdown';


const SearchFilter = (props) => {
    const dispatch = useDispatch();
    const [countries, setCounties] = useState([]);
    const filters = useSelector(propertyDataSelectors.getFilters);
    const allProperties = useSelector(propertyDataSelectors.getAllProperties);
    const [maxGuests, setMaxGuests] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);

    const onDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);

        if (startDate && endDate) {
            setDates(startDate.format("MMM DD, YYYY"), endDate.format("MMM DD, YYYY"));
        }
    };

    const fetchMaxGuests = useCallback(async () => {
        try {
          const propertyIds = allProperties.results.map((property) => property.id);
          const propertyDetailsPromises = propertyIds.map((propertyId) =>
            propertyService.getPropertyDetails({ id: propertyId })
          );
          const propertyDetailsResponses = await Promise.all(propertyDetailsPromises);
          const maxGuestsTemp = Math.max(
            ...propertyDetailsResponses.map((response) => response.property_max_guest_count)
          );
          setMaxGuests(maxGuestsTemp);
        } catch (error) {
          console.error('Error fetching max guests:', error);
        }
      }, [allProperties]);

    const fetchAvailability = async (properties, start, end) => {
        try {
            const propertyIds = properties.map((property) => property.id);
        const availabilityPromises = propertyIds.map((propertyId) => {
            return propertyService.getPropertyAvailability({
                propertyId,
                startAt: start,
                endAt: end,
            });
        });
    
            const availabilityResponses = await Promise.all(availabilityPromises);
            const availableProperties = properties.filter((property, index) =>
                availabilityResponses[index].isAvailable ? property : null
            );
    
            dispatch(propertyDataActions.setAvailableProperties(availableProperties));
        } catch (error) {
            console.error('Error fetching availability:', error);
        }
    };
    
      
    const setValue = (key, value) => {
        let obj = JSON.parse(JSON.stringify(filters));
        obj[key] = value;
        dispatch(propertyDataActions.filters(obj));
    }

    const cityChange = (selectedList, item) => {
        let obj = JSON.parse(JSON.stringify(filters));
        obj['cities'] = selectedList;
        dispatch(propertyDataActions.filters(obj));
    }

    const setDates = (start, end) => {
        let obj = JSON.parse(JSON.stringify(filters));
        obj['availabilityStart'] = moment(start, "MMM DD, YYYY").format("YYYY-MM-DD");
        obj['availabilityEnd'] = moment(end, "MMM DD, YYYY").format("YYYY-MM-DD");
        dispatch(propertyDataActions.filters(obj));
    
        if (allProperties.results && start && end) {
          fetchAvailability(allProperties.results, obj['availabilityStart'], obj['availabilityEnd']);
        }
    };
    
    const resetSelectedValues = () => {
        let obj = JSON.parse(JSON.stringify(filters));
        obj['cities'] = [];
        dispatch(propertyDataActions.filters(obj));
      };
      
      

    useEffect(() => {
        let array = [];
        if (allProperties.results) {
            fetchMaxGuests();
            allProperties.results.forEach((element, index) => {
                array.push({ country: element.country.name, city: element.city });
            });
            array = array.filter((array, index, self) => {
                return index === self.findIndex((t) => (t.country === array.country && t.city === array.city))
                })
            setCounties(array)
        }
    }, [allProperties, fetchMaxGuests]);

    const renderGuestOptions = () => {
        let options = [];
        for (let i = 1; i <= maxGuests; i++) {
          options.push(
            <option key={i} value={i}>
              {i === 1 ? "1 Guest" : `${i} Guests`}
            </option>
          );
        }
        return options;
      };

    return (
        <div className="search_filter row py-2">
            <div className="col">
  <CustomDropdown
    variant={props.variant} 
    title={filters.cities && filters.cities.length > 0 ? `${filters.cities.length} Selected` : "Select Cities"}
    options={countries}
    selectedValues={filters.cities || []}
    onChange={cityChange}
  />
</div>
            <div className="col">
                <div className="datepicker-wrapper">
            <DateRangePicker
                    small={true}
                    border={false}
                    startDate={startDate}
                    startDateId="your_unique_start_date_id"
                    endDate={endDate}
                    endDateId="your_unique_end_date_id"
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                    displayFormat="MMM DD, YYYY"
                    startDatePlaceholderText="Check in"
                    endDatePlaceholderText="Check out"
                    initialVisibleMonth={() => moment().add(2, 'days')}
                    isOutsideRange={(day) => moment().diff(day, 'days') > 0}
                />
                </div>
            </div>
            <div className="col">
                <select
                    value={filters.numberOfGuests}
                    onChange={(e) => setValue("numberOfGuests", e.target.value)}
                    className="dropdown form-control"
                >
                    <option selected={!filters.numberOfGuests} value={null}>
                    Guests
                    </option>
                    {renderGuestOptions()}
                </select>
            </div>
            <div className="col">
  <input
    type="button"
    className="btn btn-primary w-100"
    value="Search"
    onClick={() => {
        props.search();
        setTimeout(() => {
          resetSelectedValues();
        }, 3000);
      }}
  />
</div>

        </div>
    );
}

export default SearchFilter;