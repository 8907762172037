//const baseURL = `https://demo-app.lodgable.com/api/`;
// const baseURL = `https://run.mocky.io/v3/`;
const hostname = window && window.location && window.location.protocol + "//" + window.location.hostname;
// const baseURL = 'https://wonderful-perlman-bd8bc4.netlify.app/.netlify/functions/server';
const baseURL = `${hostname}/.netlify/functions/server`;
const baseURLForm = `${hostname}/.netlify/functions/form`;
const token = process.env.REACT_APP_PRODUCTION_TOKEN;

module.exports = {
	basePath: '/',
	cors: {
		origin: 'http://localhost:3000',
		allowedHeaders: 'Origin, X-Requested-With, Content-Type, Accept, Correlation-Id, T-Session-Token',
		credentials: true
	},
	propertiesSearchApi: {
		baseURL,
		endPoint: 'lodgable/property-search',
		token
	},
	propertyDetailsApi: {
		baseURL,
		endPoint: 'lodgable/property-details',
		token
	},
	propertyManagerApi: {
		baseURL,
		endPoint: 'lodgable/property-manager-details',
		token
	},
	propertyManagerInfo: {
        baseURL,
        endPoint: 'lodgable/property-manager',
        token
    },
	propertyAvailabilityApi: {
		baseURL,
		endPoint: 'lodgable/reservation-quote',
		token
	},
	countries: {
		baseURL,
		endPoint: 'lodgable/country',
		token
	},
	states: {
		baseURL,
		endPoint: 'lodgable/state-from-country',
		token
	},
	reservation: {
		baseURL,
		endPoint: 'lodgable/book-reservation-and-checkout',
		token
	},
	inquiry: {
		baseURL,
		endPoint: 'lodgable/inquiry',
		token
	},
	propertyCalendarApi: {
		baseURL,
		endPoint: 'lodgable/property',
		token
	},
	companyReviews: {
		baseURL: baseURLForm,
		endPoint: 'review/get_all/',
		token
	},
	thingsToDo: {
		baseURL: baseURLForm,
		endPoint: 'thing/get_all/',
		token
	},
	postComment: {
		baseURL: baseURLForm,
		endPoint: 'PropertyReview/save/',
		token
	},
	propertyReviews: {
		baseURL: baseURLForm,
		endPoint: 'PropertyReview/get_all/',
		token
	},
	blog: {
		baseURL: baseURLForm,
		endPoint: 'blog/get_all/',
		token
	},
	pages: {
		baseURL: baseURLForm,
		endPoint: 'page/get_all/',
		token
	},
	propertyChangeoverApi: {
        baseURL,
        endPoint: 'v2/property/change-over',
        token
    },
};