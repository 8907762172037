import * as PropTypes from 'prop-types';
import { Content } from './Styled';
import { Menu, AddressBlock, RecentViews, SocialIcons, MetaData, Pages } from '../../common';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../../common/Logo';
import { useSelector } from 'react-redux';
import { getCurrencyRate } from '../../../store/slices/propertyData/selectors';

import './simple.scss';

const Simple = (props) => {
	const { children, src, alt = true } = props;
	const currencyRate = useSelector(getCurrencyRate);


	return (
		<>
			<div className="header">
				<Container>
					<MetaData />
					<Row>
						<Col sm={3} xs={6} className=" py-1">
							<Navbar.Brand href="/"><Logo src={src} customclass={'xl'} alt={alt} /></Navbar.Brand>
						</Col>
						<Col sm={9} xs={6} className=" py-4  d-flex flex-wrap justify-content-end">
							<Navbar variant="dark" expand="xl" className="pl-0 pr-0">
								<Navbar.Toggle aria-controls="basic-navbar-nav" />
								<Navbar.Collapse id="basic-navbar-nav">
									<Nav className="ml-auto">
										<Menu phone={true} /><SocialIcons />
									</Nav>
								</Navbar.Collapse>
							</Navbar>
						</Col>
					</Row>
				</Container>
			</div>
            <div className="content" >
                <Content style={{ paddingTop: '30px' }}>
                    {children}
                </Content>
            </div>
			<div className="footer py-2">
				<Container>
					<Row>
						<Col sm={3}>
							<div className="widget">
								<h4 class="text-dark">Contact info</h4>
								<AddressBlock />
							</div>
						</Col>
						<Col sm={3}>
							<div className="widget">
								<h4 class="text-dark">Company</h4>
								<div className="links">
									<Pages footer={true} />
								</div>
							</div>
						</Col>
						<Col sm={3}>
							<div className="widget">
								<h4 class="text-dark">Useful Links</h4>
								<div className="links">
									<Menu footer={true} />
									<SocialIcons />
								</div>
							</div>
						</Col>
						<Col sm={3}>
							<div className="widget">
								<h4 class="text-dark">Recently Viewed </h4>
								<RecentViews currencyRate={currencyRate} />
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="footer-copyright py-4 text-light">
				<Container>
					<Row>
						<Col sm={12}>
							<div className="justify-content-sm-between align-items-sm-center">
								<div className="copyright-content">
									<div> <Logo src={src} customclass={'xl'} alt={alt} /> <span className="pl-3" style={{fontSize: "12px"}}>Copyright &copy; 2021. All rights reserved</span></div>
								</div>
								<div className="gototop">
									<span className={'cursor-pointer'} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Go to top</span>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

Simple.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.mode), PropTypes.node]).isRequired
}


export default Simple;