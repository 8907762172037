/* eslint-disable import/prefer-default-export */
import { PROPERTIES, PROPERTY, LOADING, ERROR, PROPERTY_DATA_SLICE, PROPERTY_MANAGER, FILTERS, ALLPROPERTIES, CURRENCY_RATE, SELECTED_CURRENCY, SELECTED_CURRENCY_SYMBOL, PROPERTY_MANAGER_INFO } from './constants';

export const getAllProperties = (state) => state[PROPERTY_DATA_SLICE][ALLPROPERTIES];
export const getProperties = (state) => state[PROPERTY_DATA_SLICE][PROPERTIES];
export const getProperty = (state) => state[PROPERTY_DATA_SLICE][PROPERTY];
export const getError = (state) => state[PROPERTY_DATA_SLICE][ERROR];
export const isLoading = (state) => state[PROPERTY_DATA_SLICE][LOADING];
export const getPropertyManager = (state) => state[PROPERTY_DATA_SLICE][PROPERTY_MANAGER];
export const getFilters = (state) => state[PROPERTY_DATA_SLICE][FILTERS];
export const getCurrencyRate = (state) => state[PROPERTY_DATA_SLICE][CURRENCY_RATE];
export const getSelectedCurrency = (state) => state[PROPERTY_DATA_SLICE][SELECTED_CURRENCY];
export const getSelectedCurrencySymbol = (state) => state[PROPERTY_DATA_SLICE][SELECTED_CURRENCY_SYMBOL];
export const getPropertyManagerInfo = (state) => state[PROPERTY_DATA_SLICE][PROPERTY_MANAGER_INFO];
