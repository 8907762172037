import Home from './Home';
import Contact from './Contact';
import About from './About';
import Properties from './Properties';
import Property from './Property';
import Payment from './Payment';
import Confirmation from './Confirmation';
import NotFound from './404';
import ThingsToDo from './ThingsToDo';
import Blogs from './Blogs';
import BlogDetails from './BlogDetails';
import CustomPage from './CustomPage';

const routeConfig = [
	{
		path: '/',
		component: Home,
		exact: true
	},
	{
		path: '/contact-us',
		component: Contact,
		exact: true
	},
	{
		path: '/about-us',
		component: About,
		exact: true
	},
	{
		path: '/our-properties',
		component: Properties,
		exact: true
	}
	,
	{
		path: '/property/details/:id',
		component: Property,
		exact: true
	},
	{
		path: '/payment',
		component: Payment,
		exact: true
	},
	{
		path: '/Confirmation',
		component: Confirmation,
		exact: true
	},
	{
		path: '/to-do',
		component: ThingsToDo,
		exact: true
	},
	{
		path: '/blogs',
		component: Blogs,
		exact: true
	},
	{
		path: '/blog/:id',
		component: BlogDetails,
		exact: true
	},
	{
		path: '/:id',
		component: CustomPage,
		exact: true
	},
	{
		path: '**',
		component: NotFound
	}

];

export default routeConfig;