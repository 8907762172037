import React from 'react';
import './PropertySkeleton.scss';

const PropertySkeleton = () => {
  return (
    <div className="property-skeleton">
      <div className="image-skeleton"></div>
      <div className="content-skeleton">
        <div className="title-skeleton"></div>
        <div className="description-skeleton"></div>
        <div className="details-skeleton"></div>
      </div>
    </div>
  );
};

export default PropertySkeleton;