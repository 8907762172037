import { Link } from 'react-router-dom';
import { FaPhoneAlt } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux';
import { propertyDataSelectors, propertyDataActions } from '../../../store/slices/propertyData';
import GoogleTranslate from '../../../services/GoogleTranslate';
import CurrencySelector from '../Currencies/CurrencySelector';
import { useEffect, useState } from 'react';

const Menu = (props) => {
	const [isLoadingManagerInfo, setIsLoadingManagerInfo] = useState(true); 
	const manager = useSelector(propertyDataSelectors.getPropertyManager);
	const dispatch = useDispatch();
	const managerInfo = useSelector(propertyDataSelectors.getPropertyManagerInfo);
	const selectedCurrency = useSelector(propertyDataSelectors.getSelectedCurrency);
	const excludedManagerId = 2435;


	const handleCurrencyRateChange = (newRate) => {
	  dispatch(propertyDataActions.setCurrencyRate(newRate));
	};
  
	const renderThingsToDoLink = () => {
        if (manager.id !== excludedManagerId) {
            return (
                <Link className={window.location.pathname === '/to-do' ? "nav-link nav_active" : "nav-link"} 
                    style={{ whiteSpace: 'nowrap' }} to="/to-do">Things To Do</Link>
            );
        }
        return null;
    };

	useEffect(() => {
	  (async () => {
		setIsLoadingManagerInfo(true);
		await dispatch(propertyDataActions.propertyManagerInfo());
		setIsLoadingManagerInfo(false);
	  })();
	}, [dispatch]);
  
	useEffect(() => {
	  if (!isLoadingManagerInfo && managerInfo && !selectedCurrency) {
		const baseCurrency = managerInfo.currency === 13 ? 'MXN' : 'USD';
		const baseCurrencySymbol = baseCurrency === 'MXN' ? 'MX$' : '$';
		
		dispatch(propertyDataActions.setSelectedCurrency(baseCurrency));
		dispatch(propertyDataActions.setSelectedCurrencySymbol(baseCurrencySymbol));
	  }
	}, [isLoadingManagerInfo, managerInfo, selectedCurrency, dispatch]);
  
	const baseCurrency = managerInfo ? (managerInfo.currency === 13 ? 'MXN' : 'USD') : 'USD'; // Default to USD if managerInfo is not available
  
	return (
		<>
		{!props.footer ? (
			<div className="p-2 d-flex align-items-center">
				<div style={{ position: 'relative', marginRight: '7px' }}>
					<GoogleTranslate />
					<div id="google_translate_element" style={{ display: 'none' }}></div>
				</div>
				<CurrencySelector baseCurrency={baseCurrency} onRateChange={handleCurrencyRateChange} />
			</div>
		) : null}
			{props.phone ? <a className={window.location.pathname === '/' ? "nav-link" : "nav-link"} href={`tel:${manager.customerServicePhone}`}  style={{ whiteSpace: 'nowrap' }}><FaPhoneAlt /> {manager.customerServicePhone}</a> : null}
		<Link className={window.location.pathname === '/' ? "nav-link nav_active" : "nav-link"} style={{ whiteSpace: 'nowrap' }} to="/">Home</Link>
		<Link className={window.location.pathname === '/our-properties' ? "nav-link nav_active" : "nav-link"} style={{ whiteSpace: 'nowrap' }} to="/our-properties">Our Properties</Link>
		{renderThingsToDoLink()}
		{props.footer ? <Link className={window.location.pathname === '/blogs' ? "nav-link nav_active" : "nav-link"} style={{ whiteSpace: 'nowrap' }} to="/blogs">Blogs</Link> : null}
		{props.footer ? null : <Link className={window.location.pathname === '/about-us' ? "nav-link nav_active" : "nav-link"} style={{ whiteSpace: 'nowrap' }} to="/about-us">About Us</Link>}
		{props.footer ? null : <Link className={window.location.pathname === '/contact-us' ? "nav-link nav_active" : "nav-link"} style={{ whiteSpace: 'nowrap' }} to="/contact-us">Contact Us</Link>}
	</>
	);
};


export default Menu;