import React, { useEffect, useState } from "react";
import { Simple } from '../../components/layouts';
import { Row, Col, Container } from 'react-bootstrap';
import image from '../../assets/1503.png';
import recent_img from '../../assets/about-gallery.jpg';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { propertyDataSelectors } from '../../store/slices/propertyData';
import { useSelector } from 'react-redux';
import config from '../../config';
import axios from 'axios';
import { Loading } from '../../components/common';
import './blog.scss';

const Blogs = () => {
	const manager = useSelector(propertyDataSelectors.getPropertyManager);
	const [blogs, setBlogs] = useState([]);
	const [loading, setLoading] = useState(true);

	const getCompanyReviews = async (id) => {
		try {
			const { endPoint, baseURL, token } = config.blog;
			const response = await axios.get(`${baseURL}?endpoint=${endPoint}${id}`, { headers: { "Authorization": `Bearer ${token}` } });
			setLoading(false)
			setBlogs(response.data);
		} catch (err) {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (manager.id) {
			getCompanyReviews(manager.id);
		}
	}, [manager]); // eslint-disable-line react-hooks/exhaustive-deps

	const getImageUrl = (htmlstring) => {
		if (htmlstring.includes('<img')) {
			let url = htmlstring.split('src="').pop().split('"')[0];
			return url;
		}
		return recent_img;
	}

	return (
		<Simple src={image} alt='logo'>
			<Container className="mt-5 mb-4">
				<Row>
					<Col sm={12}>
						<Breadcrumb>
							<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
							<Breadcrumb.Item active>Blogs</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
				</Row>
				<Row className="mt-4 ">
					<Col sm={12}>
						<h4 className="subheading pb-3 mb-4">Blogs</h4>
					</Col>
				</Row>
				<Row className="mt-4 justify-content-center text-center">
					<Col sm={12}>
						<Loading isLoading={loading} />
					</Col>
					{!loading && blogs.length === 0 ? <Col sm={12} className={'text-center'}>
						<h4>No records found...</h4>
					</Col> : null}
					<Col sm={12}>
						<Row className="about-gallery">
							{blogs.map(el => {
								el = el.Blog;
								return (
									<Col md={4} sm={12} xs={12}>
										<Card>
											<Link to={`/blog/${el.id}`}>
												<Card.Text>
													<div className="card-meta"></div>
													<h2 className="card-title"> {el.title}</h2>
												</Card.Text>
												<img alt="image1" className="card-image" src={getImageUrl(el.description)} />
											</Link>
										</Card>
									</Col>
								)
							})}
						</Row>
					</Col>
				</Row>
			</Container>
		</Simple >
	)
};

export default Blogs;