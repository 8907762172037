import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import './SendInquiryForm.scss';
import propertyService from '../../../services/propertyService';
import loader from '../../../assets/loading.svg';
import * as moment from 'moment';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { DateRangePicker } from 'react-dates';


function SendInquiryForm(props) {
    const [values, setValues] = useState({
        inquirersName: '',
        contactEmail: '',
        subject: '',
        message: '',
        checkIn: '',
        checkOut: ''
    });

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [focusedInput, setFocusedInput] = useState(null);
    const [blockedDays, setBlockedDays] = useState(new Set());
    const [isDateSelected, setDateSelected] = useState(false);


    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleDatesChange = ({ startDate, endDate }) => {
        let obj = JSON.parse(JSON.stringify(values));
        obj['checkIn'] = startDate ? startDate.format("YYYY-MM-DD") : '';
        obj['checkOut'] = endDate ? endDate.format("YYYY-MM-DD") : '';
    
        // Check if both checkIn and checkOut dates are selected
        if (obj['checkIn'] !== '' && obj['checkOut'] !== '') {
            setDateSelected(true);
        } else {
            setDateSelected(false);
        }
    
        setValues(obj);
    };
    const handleFocusChange = focusedInput => {
        setFocusedInput(focusedInput);
    };

    const changeValues = (value, key) => {
        setMessage('');
        document.getElementById(key).classList.remove("is-invalid");
        let data = JSON.parse(JSON.stringify(values));
        data[key] = value;
        setValues(data);
    }

    const validate = (obj) => {
        let status = true;
        Object.keys(obj).forEach(key => {
            if (['inquirersName', 'contactEmail', 'subject', 'message'].includes(key)) {
                if (!obj[key]) {
                    status = false;
                    document.getElementById(key).classList.add("is-invalid");
                } else if (key === 'contactEmail' && !validateEmail(obj[key])) {
                    status = false;
                    document.getElementById(key).classList.add("is-invalid");
                }
            }
        });
        return status;
    }

    const isCheckinDayBlocked = (day) => {
        // Define the format used to compare dates
        const dateFormat = 'YYYY-MM-DD';
        
        // Format the day to the same format
        const currentDay = day.format(dateFormat);
        
        // Check if the day is a blocked day
        const isDayBlocked = blockedDays.has(currentDay);
        
        return isDayBlocked;
    };
    
    
    const isCheckoutDayBlocked = (day) => {
        // Define the format used to compare dates
        const dateFormat = 'YYYY-MM-DD';
    
        // Format the day to the same format
        const currentDay = day.format(dateFormat);
    
        // Check if the day is a blocked day
        const isDayBlocked = blockedDays.has(currentDay);

        return isDayBlocked;
    };

    const handleSubmit = async () => {
        if(!props.property_id){
            let user_captcha_value = document.getElementById('user_captcha_input').value;
            if (!validateCaptcha(user_captcha_value, false)) {
                return setMessage('Captcha Does Not Match');
            }
        }
        setMessage('');
        let obj = JSON.parse(JSON.stringify(values));
        if (!validate(obj)) {
            return
        }
        setLoading(true);
        if (props.property_id) {
            obj['property'] = props.property_id;
        }

        try {
            let availabilityData = await propertyService.getPropertyAvailability({
                propertyId: props.property_id, 
                startAt: obj['checkIn'], 
                endAt: obj['checkOut'], 
                guestCount: 1, 
                customSite: 1 
            });

            if (!availabilityData.isAvailable) {
                // If the property is not available for the selected dates, stop here and show a message to the user.
                setMessage('The property is not available for the selected dates.');
                setLoading(false);
                return;
            }

            await propertyService.sendInquiry(obj);
            setValues({
                inquirersName: '',
                contactEmail: '',
                subject: '',
                message: '',
                checkIn: '',
                checkOut: ''
            })
        } catch (err) {
            console.log(err);
        }
        setMessage('Inquiry sent successfully.');
        setLoading(false);
    }

    useEffect(() => {
        async function fetchCalendarData() {
            const twoYearsFromNow = moment().add(2, 'years').format('YYYY-MM-DD');
            const response = await propertyService.getPropertyCalendar({ propertyId: props.property_id }, twoYearsFromNow);

            let newBlockedDays = new Set();
            response.forEach(data => {
                if (!data.available) {
                    const rangeStart = moment(data.startAt.date);
                    const rangeEnd = moment(data.endAt.date);
                    let day = moment(rangeStart);
                    while (day.isSameOrBefore(rangeEnd)) {
                        newBlockedDays.add(day.format('YYYY-MM-DD'));
                        day.add(1, 'day');
                    }
                }
            });
            
            setBlockedDays(newBlockedDays);
        }
    
        fetchCalendarData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (!props.property_id) {
            loadCaptchaEnginge(6);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Form>
            <Row>
                <Col sm={6}>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter Name" id="inquirersName" onChange={(e) => changeValues(e.target.value, 'inquirersName')} />
                        <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" id="contactEmail" onChange={(e) => changeValues(e.target.value, 'contactEmail')} />
                        <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                <Form.Label>Dates</Form.Label>
                            <DateRangePicker
                noBorder={true}
                startDate={values.checkIn ? moment(values.checkIn) : null}
                startDateId="checkIn"
                endDate={values.checkOut ? moment(values.checkOut) : null}
                endDateId="checkOut"
                onDatesChange={handleDatesChange}
                focusedInput={focusedInput}
                onFocusChange={handleFocusChange}
                numberOfMonths={1}
                showClearDates={true}
                startDatePlaceholderText="Check In"
                endDatePlaceholderText="Check Out"
                isDayBlocked={day => {
                    if (focusedInput === "startDate") {
                        return isCheckinDayBlocked(day);
                    } else if (focusedInput === "endDate") {
                        return isCheckoutDayBlocked(day);
                    }
                    return false;
                }}
            />

                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <Form.Control type="text" placeholder="Enter Subject" id="subject" onChange={(e) => changeValues(e.target.value, 'subject')} />
                        <Form.Text className="text-muted">
                            We'll never share your Subject with anyone else.
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Form.Group>
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" placeholder="Enter Message" id="message" rows={3} onChange={(e) => changeValues(e.target.value, 'message')} />
                    </Form.Group>
                </Col>
            </Row>
            {!props.property_id ? <Row>
                <Col sm={12} className="py-2">
                    <input placeholder="Enter captcha text" type="text" id="user_captcha_input" class="form-control mb-2" />
                    <LoadCanvasTemplate />
                </Col>
            </Row> : null}
            <Row>
                <Col sm={12} className="py-2">
                    <span className="text-success">{message}</span>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                <Button variant="primary" disabled={!isDateSelected || loading} className="btn btn-primary" onClick={() => handleSubmit()}>
                    {loading ? <img style={{ width: '20%' }} src={loader} alt='loading' /> : 'Submit'}
                </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default SendInquiryForm;