import React from 'react';
// import { Form } from 'react-bootstrap';
import './moreFilter.scss';
import { propertyDataSelectors, propertyDataActions } from '../../../store/slices/propertyData';
import { useSelector, useDispatch } from 'react-redux';

function MoreFilters(props) {

    const dispatch = useDispatch();
    const filters = useSelector(propertyDataSelectors.getFilters);

    const setValue = (key, value) => {
        let obj = JSON.parse(JSON.stringify(filters));
        obj[key] = value;
        dispatch(propertyDataActions.filters(obj));
    }

    return (
        <aside className="aside_widget more_filter mb-4">
            <h5 className="heading pb-3 mb-4">More Filters</h5>
            <select value={filters.bedrooms} onChange={(e) => setValue('bedrooms', e.target.value)} className="dropdown form-control my-2">
                <option selected={!filters.bedrooms} disabled>Bedrooms</option>
                <option value="1">1 Bedroom</option>
                <option value="2">2 Bedroom</option>
                <option value="3">3 Bedroom</option>
                <option value="4">4 Bedroom</option>
                <option value="5">5 Bedroom</option>
            </select>
            <select value={filters.bathrooms} onChange={(e) => setValue('bathrooms', e.target.value)} className="dropdown form-control my-2">
                <option selected={!filters.bathrooms} disabled>Bathrooms</option>
                <option value="1">1 Bathroom</option>
                <option value="2">2 Bathroom</option>
                <option value="3">3 Bathroom</option>
                <option value="4">4 Bathroom</option>
            </select>
            <select value={filters.beds} onChange={(e) => setValue('beds', e.target.value)} className="dropdown form-control my-2">
                <option selected={!filters.beds} disabled>Beds</option>
                <option value="1">1 Bed</option>
                <option value="2">2 Bed</option>
                <option value="3">3 Bed</option>
                <option value="4">4 Bed</option>
                <option value="5">5 Bed</option>
                <option value="6">6 Bed</option>
                <option value="7">7 Bed</option>
                <option value="8">8 Bed</option>
            </select>
            {/* <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Instant Bookable" />
            </Form.Group>
            <select className="dropdown form-control my-2">
                <option disabled>Amenities</option>
                <option>Air condtioning</option>
                <option>Heating</option>
            </select> */}

            <select value={filters.propertyType} onChange={(e) => setValue('propertyType', e.target.value)} className="dropdown form-control my-2">
                <option selected={!filters.propertyType} disabled>Property Type</option>
                <option value="2">Apartment/Condo</option>
                <option value="1">Aparthotel</option>
                <option value="18">Villa</option>
                <option value="11">Hotel</option>
                <option value="6">Castle</option>
                <option value="12">House</option>
                <option value="5">Cabin</option>
                <option value="20">Other</option>
            </select>
            <input type="button" className="btn btn-primary w-100 mt-3 radiusedge" onClick={() => { props.search() }} value="Update Results" />
            <p className="text-center text-muted py-3 radiusedge"><span onClick={() => { props.clearFilters() }} className='cursor-pointer'>Clear Filters</span></p>
        </aside>

    );
}

export default MoreFilters;